// import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import {
  ChevronDoubleRightIcon,
  ChevronDoubleLeftIcon,
} from "@heroicons/react/24/solid";
// import { useNavigate } from "react-router-dom";
import { useFilters, usePagination, useSortBy, useTable } from "react-table";
// import VerificationConfirmationModal from 'components/DomainOwnershipCheck/VerificationConfirmationModal';
// import VerificationFailedModal from 'components/DomainOwnershipCheck/VerificationFailedModal';
// import VerificationSuccessModal from 'components/DomainOwnershipCheck/VerificationSuccessModal';
// import Loader from 'components/Loader';
import CardMenu from "components/card/CardMenu";
// import Checkbox from "components/checkbox";
import Card from "components/card";

// import { gql } from '@apollo/client';
// import { MARK_PRODUCT_VERIFIED } from "graphql/products/mutations";
// import { ADD_PRODUCT_MUTATION } from 'graphql/products/queries';
// import { FaCheckCircle } from 'react-icons/fa';
// import { useAuth } from "App";
// import jwtDecode from "jwt-decode";
// import { GET_USER_BY_ID } from "graphql/auth/queries";
// import { CREATE_NOTIFICATION_MUTATION } from "graphql/notifications/mutations";

// const VERIFY_DOMAIN_OWNERSHIP = gql`
//   mutation VerifyDomainOwnership($domain: String!) {
//     verifyDomainOwnership(domain: $domain) {
//       status
//     }
//   }
// `;

// const CREATE_TXT_RECORD = gql`
//   mutation CreateTxtRecord(
//       $domain: String!
//     ) {
//     createTxtRecord(
//         domain: $domain
//     ) {
//       id,
//       randomString
//     }
//   }
// `;

const RejectedDomainsTable = ({ domains }) => {
  // const navigate = useNavigate();

  // const [verificationRecord, setVerificationRecord] = useState('');
  // const [verificationStatus, setVerificationStatus] = useState('');
  // const [verificationDomain, setVerificationDomain] = useState('');
  // const [loaderOpen, setLoaderOpen] = useState(false);
  // const [openVerificationConfirmationModal, setOpenVerificationConfirmationModal] = useState(false);
  // const [openVerificationFailedModal, setOpenVerificationFailedModal] = useState(false);
  // const [openVerificationSuccessModal, setOpenVerificationSuccessModal] = useState(false);
  // const [verificationFinalStatusData, setVerificationFinalStatusData] = useState(false);
  // const [merchantId, setMerchantId] = useState('');
  // // Destructure the mutation function and loading state from the useMutation hook
  // const [createTxtRecord, { loadingCreateTxtRecord }] = useMutation(CREATE_TXT_RECORD);
  // const [verifyDomainOwnership, { loadingVerificationData }] = useMutation(VERIFY_DOMAIN_OWNERSHIP);

  // const [markProductVerified, { markProductVerifiedError, markProductVerifiedLoading, markProructVerifiedData }] = useMutation(MARK_PRODUCT_VERIFIED);
  // const [createNotification, { createNotificationError, createNotificationLoading, createNotificationData }] = useMutation(CREATE_NOTIFICATION_MUTATION);
  // // console.log("Graphql Query: ", MARK_PRODUCT_VERIFIED?.loc?.source?.body);
  // const [createProduct, { productCreateError, productCreateLoading }] = useMutation(ADD_PRODUCT_MUTATION)
  // // console.log("Graphql Query: ", ADD_PRODUCT_MUTATION?.loc?.source?.body);

  // const { token } = useAuth();
  // const user = jwtDecode(token);
  // console.log("User: ", user);
  // console.log(user.sub);
  // const { loading, error, dataUser, refetch } = useQuery(GET_USER_BY_ID, {
  //   variables: { "id": user.sub }, // Replace with the domain you want to query
  // });
  // console.log("Graphql Query: ", GET_USER_BY_ID?.loc?.source?.body);
  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error: {error.message}</p>;
  // console.log(dataUser, error, loading);

  // Define the columns based on your data structure
  const columns = React.useMemo(
    () => [
      {
        Header: "Domain",
        accessor: "word",
      },
      {
        Header: "Buy Now Price",
        accessor: "buyNowPrice",
      },
      {
        Header: "Floor Price",
        accessor: "floorPrice",
      },
      {
        Header: "Minimum Offer",
        accessor: "minimumOffer",
      },
      // {
      //   Header: "Action",
      //   Cell: ({ row }) => {
      //     return row.original.isVerified ?
      //       <span style={{ display: 'flex', alignItems: 'center' }}>
      //         <FaCheckCircle size={24} color="green" style={{ marginRight: '4px' }} />
      //         Verified Domain
      //       </span> :
      //       <button className="beta-marker top-4 right-4 bg-red-600 text-white py-1 px-3 rounded-2xl font-semibold" onClick={() => handleCreateTxtRecord(row)}>Verify</button>
      //   },
      // },
    ],
    []
  );

  const data = React.useMemo(() => domains, [domains]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const {
    // getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    nextPage, // Function to go to the next page
    previousPage, // Function to go to the previous page
    canNextPage, // Boolean indicating if there's a next page
    canPreviousPage, // Boolean indicating if there's a previous page
    pageOptions, // An array of available page numbers
    state: { pageIndex, filters }, // Get the current page index
  } = tableInstance;

  // const updateDomainVerified = async (id) => {
  //   // console.log(id);
  //   // console.log(verificationDomain);
  //   try {
  //     const response = await markProductVerified({
  //       variables: {
  //         "markVerifiedInput": { "id": parseInt(id), "isVerified": true }
  //       }
  //     })
  //     // console.log("First API Response: ", response)

  //     if (!response.data) {
  //       throw ("Something went Wrong while setting Verification Status true.");
  //     }

  //     // console.log("Domain: ", verificationDomain.original.word)
  //     // console.log("Domain: ", verificationDomain.original.buyNowPrice);

  //     const res = await createProduct({
  //       variables: {
  //         createProductInput: {
  //           title: verificationDomain.original.word,
  //           price: verificationDomain.original.buyNowPrice,
  //           vendor: merchantId,
  //         },
  //       },
  //     });

  //     // console.log("Second API res", res)

  //     if (res.data) {
  //       navigate('/portfolio/my_domains')
  //     }

  //     // console.log('Password saved:', response);
  //   } catch (error) {
  //     console.error('Error saving password:', error);
  //     // setError('Domain List not valid. Please Correct the same first.')
  //   }
  // }

  // const handleCreateTxtRecord = async (product) => {
  //   // console.log(product);
  //   // console.log(product);

  //   // console.log("hello");
  //   // Call the mutation function
  //   const response = await refetch({ variables: { id: user.sub } });
  //   setMerchantId(response.data.getUserById.merchant_id);
  //   // console.log(response);
  //   setVerificationDomain(product)

  //   const submitData = createTxtRecord({
  //     variables: {
  //       domain: product.original.word,
  //     }
  //   }
  //   ).then((res) => {
  //     if (res.data) {
  //       // console.log(res.data.createTxtRecord)
  //       // const verificationRecord = res.data.createTxtRecord.randomString;
  //       // console.log(verificationRecord)
  //       setVerificationRecord(res.data.createTxtRecord.randomString);
  //       setOpenVerificationConfirmationModal(true);
  //       setVerificationStatus('pending');
  //     } else {
  //       throw ("Something went Wrong");
  //     }
  //   }).catch((err) => {
  //     console.log(err);
  //   })

  //   // createTxtRecord({
  //   //   variables: { domain },
  //   // })
  //   //   .then((response) => {
  //   //     console.log('Mutation response:', response.data);
  //   //     // Do something with the response if needed
  //   //   })
  //   //   .catch((error) => {
  //   //     console.error('Mutation error:', error);
  //   //     // Handle any errors that occurred during the mutation
  //   //   });
  // };

  // const handleVerificationStatus = () => {
  //   setLoaderOpen(true);
  //   let elapsedTime = 0; // Initialize elapsed time
  //   const interval = setInterval(() => {
  //     elapsedTime += 5000; // Increment elapsed time by 5 seconds
  //     // console.log(verificationDomain);
  //     const submitData = verifyDomainOwnership({
  //       variables: {
  //         domain: verificationDomain.original.word,
  //       }
  //     }
  //     ).then((res) => {
  //       if (res.data) {
  //         // console.log(res);
  //         const status = res.data.verifyDomainOwnership.status;
  //         // setVerificationRecord(res.data.verifyDomainOwnership.verificationRecord);
  //         // setOpenVerificationConfirmationModal(true);
  //         // setVerificationStatus('pending');
  //         // console.log(response);
  //         // const { status } = response.data;
  //         // console.log(response.data);
  //         setVerificationStatus(status);
  //         if (status === 'verified') {
  //           setLoaderOpen(true);
  //           clearInterval(interval);
  //           setOpenVerificationConfirmationModal(false);
  //           setOpenVerificationSuccessModal(true)
  //           setVerificationFinalStatusData(true)
  //           updateDomainVerified(verificationDomain.original.id);
  //         } else if (status === 'failed') {
  //           setLoaderOpen(false);
  //           clearInterval(interval);
  //           setOpenVerificationConfirmationModal(false);
  //           setOpenVerificationFailedModal(true);
  //         } else {
  //           // console.log(true);
  //           setLoaderOpen(true);
  //         }
  //       } else {
  //         throw ("Something went Wrong");
  //       }
  //       // Check if elapsed time is greater than 3 minutes (180000 ms)
  //       if (elapsedTime > 180000) {
  //         clearInterval(interval);
  //         setLoaderOpen(false);
  //         setOpenVerificationConfirmationModal(false);
  //         setOpenVerificationFailedModal(true);
  //       }
  //     }).catch((err) => {
  //       console.log(err);
  //     })
  //   }, 5000);

  // }

  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error: {error.message}</p>;
  // console.log(dataUser);

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Rejected Domains
        </div>
        {/* <div className="flex items-center">
          <input
            type="text"
            id="search"
            value={filters[0]?.value || ""}
            onChange={(e) => {
              tableInstance.setFilter("title", e.target.value); // Apply the filter to the "title" column using tableInstance
            }}
            placeholder="Search domains..."
            className="rounded-md border px-3 py-2 transition duration-300 focus:border-blue-500 focus:outline-none"
          />
        </div> */}
        <CardMenu />
      </header>

      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
        <table
          // className="min-w-full divide-y divide-gray-300" {...getTableProps()}
          className="w-full"
          variant="simple"
          color="gray-500"
          mb="24px"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, columnIndex) => (
                  <th
                    className="border-b border-gray-200 pb-[10px] pr-8 text-start dark:!border-navy-700"
                    {...column.getHeaderProps(column.getSortByToggleProps())} // Updated line
                    key={columnIndex}
                  >
                    <div>
                      {column.render("Header")}
                      <span className="text-xs">
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " ▼"
                            : " ▲"
                          : " ▲▼"}
                      </span>
                    </div>
                    {/* {column.render('Filter')} */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="divide-y divide-gray-200" {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      className="pb-[16px] pt-[14px] sm:text-[14px]"
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        {rows.length === 0 && (
          <tr className="flex h-32  w-full items-center justify-center font-semibold text-red-600">
            No data found
          </tr>
        )}

        {/* <VerificationConfirmationModal
          data={
            { verificationRecord: verificationRecord, domain: verificationDomain?.original?.word }
          }
          openVerificationConfirmationModal={openVerificationConfirmationModal}
          handleVerificationStatus={handleVerificationStatus}
        />

        <Loader loaderOpen={loaderOpen} setLoaderOpen={setLoaderOpen} />

        <VerificationFailedModal
          data={{
            openVerificationFailedModal: openVerificationFailedModal,
            setOpenVerificationFailedModal: setOpenVerificationFailedModal,
          }}
        />

        <VerificationSuccessModal
          data={{
            openVerificationSuccessModal: openVerificationSuccessModal,
            setOpenVerificationSuccessModal: setOpenVerificationSuccessModal,
          }}
        /> */}
      </div>
      <div className="mt-8 flex items-center justify-between pb-4">
        <div className="flex items-center justify-between gap-1 md:mt-8 md:pb-4">
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="flex cursor-pointer items-center rounded-lg bg-[#01afef] px-4 py-2 text-sm font-medium text-white"
          >
            <ChevronDoubleLeftIcon className="h-4 w-4 text-white" /> Previous
          </button>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="flex cursor-pointer items-center rounded-lg bg-[#01afef] px-4 py-2 text-sm font-medium text-white"
          >
            Next <ChevronDoubleRightIcon className="h-4 w-4 text-white" />
          </button>
        </div>
        <div>
          Page <strong>{pageIndex + 1}</strong> of{" "}
          <strong>{pageOptions.length}</strong>
        </div>
      </div>
    </Card>
  );
};

export default RejectedDomainsTable;
