// EmailVerificationRoute.js

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { VERIFY_EMAIL_MUTATION } from 'graphql/auth/mutations'; // Import your GraphQL mutation
import EmailVerificationSuccess from 'views/email-verification/EmailVerificationSuccess';
import EmailVerificationFailed from 'views/email-verification/EmailVerificationFailed.';
import Loader from 'components/Loader';

const EmailVerification = () => {
  const location = useLocation();
  const [verificationResult, setVerificationResult] = useState(null);
  const [loaderOpen, setLoaderOpen] = useState(false);

  const [verifyEmail] = useMutation(VERIFY_EMAIL_MUTATION);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    if (token) {
      // Call your GraphQL mutation to verify the email
      setLoaderOpen(true);
      verifyEmail({ variables: { input: { token } } })
        .then(result => {
          setVerificationResult(result.data.verifyEmail);
          setLoaderOpen(false);
        })
        .catch(error => {
          console.error('Email verification failed:', error);
          setVerificationResult('Email verification failed.');
          setLoaderOpen(false);
        });
    } else {
      setVerificationResult('Token not found in the URL.');
      setLoaderOpen(false);
    }
  }, [location.search, verifyEmail]);

  return (
    <div>
      <Loader loaderOpen={loaderOpen} setLoaderOpen={setLoaderOpen} />
      <h2>{verificationResult}</h2>
      {verificationResult === 'Email verification successful.' ? <EmailVerificationSuccess /> : <EmailVerificationFailed />}
    </div>
  );
};

export default EmailVerification;
