import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
// import NFTMarketplace from "views/admin/marketplace";
// import Profile from "views/admin/profile";
// import DataTables from "views/admin/tables";
// import RTLDefault from "views/rtl/default";

// Auth Imports
import SignIn from "views/auth/SignIn";
import Register from "views/auth/Register";

// Icon Imports
import {
  MdHome,
  // MdOutlineShoppingCart,
  // MdBarChart,
  // MdPerson,
  // MdLock,
  MdShoppingBag,
  // MdOutlineLeaderboard,
  // MdLocalOffer,
  // MdSettings,
  // MdDomain,
} from "react-icons/md";
// import DomainOwnershipCheck from "layouts/domain-ownership-check/DomainOwnershipCheck";
// import { FaFileInvoice } from "react-icons/fa";
import { FiLogIn } from "react-icons/fi";
import PortfolioOverview from "views/admin/portfolio/";
import AddProduct from "views/admin/portfolio/AddProduct";
import ApproveDomains from "views/admin/portfolio/ApproveDomains";
import MyDomains from "views/admin/portfolio/MyDomains";
import AllDomains from "views/admin/portfolio/AllDomains";
import EditDomain from "views/admin/portfolio/EditDomain";
// import ProfileOverview from "views/admin/profile";
import MyAccount from "views/admin/profile/MyAccount";
import DomainAnalysis from "views/admin/portfolio/DomainAnalysis";
import MyLiveDomains from "views/admin/portfolio/MyLiveDomains";
import MyApprovedDomains from "views/admin/portfolio/MyApprovedDomains";
import RejectedDomains from "views/admin/portfolio/RejectedDomains";
import MyRejectedDomains from "views/admin/portfolio/MyRejectedDomains";
import EmailVerificationFailed from "views/email-verification/EmailVerificationFailed.";
import EmailVerificationSuccess from "views/email-verification/EmailVerificationSuccess";
import ForgotPassword from "./views/auth/ForgotPassword";
import EmailVerification from "views/auth/EmailVerification";

const routes = [
  // {
  //   name: "Dashboard",
  //   layout: "/admin",
  //   path: "default",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <MainDashboard />,
  //   accessRoles: ['Admin']
  // },
  {
    name: "Portfolio",
    layout: "/portfolio",
    path: "",
    icon: <MdShoppingBag className="h-6 w-6" />,
    component: <PortfolioOverview />,
    children: [
      { name: "Approve Domains", layout: "", path: "approve_domains", icon: "", component: <ApproveDomains />, accessRoles: ['Admin'] },
      { name: "All Approved Domains", layout: "", path: "", icon: "", component: <AllDomains />, accessRoles: ['Admin'] },
      { name: "My Approved Domains", layout: "", path: "my_approved_domains", icon: "", component: <MyApprovedDomains />, accessRoles: ['Vendor'] },
      { name: "My Rejected Domains", layout: "", path: "my_rejected_domains", icon: "", component: <MyRejectedDomains />, accessRoles: ['Vendor'] },
      { name: "My Live Domains", layout: "", path: "my_live_domains", icon: "", component: <MyLiveDomains />, accessRoles: ['Vendor'] },
      { name: "Add Domains", layout: "", path: "add", icon: "", component: <AddProduct />, accessRoles: ['Vendor'] },
      { name: "All Rejected Domains", layout: "", path: "rejected_domains", icon: "", component: <RejectedDomains />, accessRoles: ['Admin'] },
      { name: "Pending For Approval", layout: "", path: "my_domains", icon: "", component: <MyDomains />, accessRoles: ['Vendor'] },
      { name: "", layout: "", path: "analytics/:handle", icon: "", component: <DomainAnalysis />, accessRoles: ['Vendor, Admin'] },
      { name: "", layout: "", path: "edit-domain/*", icon: "", component: <EditDomain />, accessRoles: ['Vendor', 'Admin'] },
      // { name: "All Domains", layout: "", path: "all_domains", icon: "", component: <AllDomains /> },
      // { name: "Leased Out", layout: "/portfoflio", path: "leased", icon: "", component: "", },
      // { name: "Parking", layout: "/portfoflio", path: "parked", icon: "", component: "", },
      // { name: "Sold", layout: "/portfoflio", path: "sold", icon: "", component: "", },
    ]
  },
  {
    name: "",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
    children: [
      { name: "", layout: "", path: "my_account", icon: "", component: <MyAccount />, accessRoles: [] }
    ]
  },
  // {
  //   name: "Lead Center",
  //   layout: "/profile",
  //   path: "default",
  //   icon: <MdOutlineLeaderboard className="h-6 w-6" />,
  //   component: <MainDashboard />,
  //   children: [
  //     { name: "Brokered Leads", layout: "", path: "", icon: "", component: "" },
  //   ]
  // },
  // {
  //   name: "Purchases",
  //   layout: "/admin",
  //   path: "default",
  //   icon: <FaFileInvoice className="h-6 w-6" />,
  //   component: <MainDashboard />,
  //   children: [
  //     { name: "Domains", layout: "", path: "", icon: "", component: "" },
  //     { name: "BrandCart Agent", layout: "", path: "", icon: "", component: "" },
  //     { name: "Appraisals", layout: "", path: "", icon: "", component: "" },
  //   ]
  // },
  // {
  //   name: "Offers",
  //   layout: "/admin",
  //   path: "default",
  //   icon: <MdLocalOffer className="h-6 w-6" />,
  //   component: <MainDashboard />,
  //   children: [
  //     { name: "Buying Offers", layout: "", path: "", icon: "", component: "" },
  //     { name: "Selling Offers", layout: "", path: "", icon: "", component: "" },
  //     { name: "Price Requests", layout: "", path: "", icon: "", component: "" },
  //   ]
  // },
  // {
  //   name: "Settings",
  //   layout: "/admin",
  //   path: "default",
  //   icon: <MdSettings className="h-6 w-6" />,
  //   component: <MainDashboard />,
  //   children: [
  //     { name: "Account Details", layout: "", path: "", icon: "", component: "" },
  //     { name: "Statements", layout: "", path: "", icon: "", component: "" },
  //     { name: "Sale Lander Settings", layout: "", path: "", icon: "", component: "" },
  //     { name: "Ownership Settings", layout: "", path: "", icon: "", component: "" },
  //   ]
  // },
  // {
  //   name: "Sign In",
  //   layout: "/auth",
  //   path: "sign-in",
  //   icon: <FiLogIn className="h-6 w-6" />,
  //   component: <SignIn />,
  // },
  // {
  //   name: "Register",
  //   layout: "/auth",
  //   path: "register",
  //   icon: <FiLogIn className="h-6 w-6" />,
  //   component: <Register />,
  // },
  {
    name: "",
    layout: "/auth",
    path: "sign-in",
    icon: <FiLogIn className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "",
    layout: "/auth",
    path: "register",
    icon: <FiLogIn className="h-6 w-6" />,
    component: <Register />,
  },
  {
    name: "",
    layout: "/auth",
    path: "forgot-password",
    icon: <FiLogIn className="h-6 w-6" />,
    component: <ForgotPassword />,
  },
  {
    name: "",
    layout: "/auth",
    path: "verify-token",
    icon: <FiLogIn className="h-6 w-6" />,
    component: <EmailVerification />,
  },
  {
    name: "",
    layout: "/email-verification",
    path: "success",
    icon: <FiLogIn className="h-6 w-6" />,
    component: <EmailVerificationSuccess />,
  },
  {
    name: "",
    layout: "/email-verification",
    path: "failed",
    icon: <FiLogIn className="h-6 w-6" />,
    component: <EmailVerificationFailed />,
  },
  // {
  //   name: "Domain Ownership Check",
  //   layout: "/domain-ownership-check",
  //   path: "domain-ownership-check",
  //   icon: <MdDomain className="h-6 w-6" />,
  //   component: <DomainOwnershipCheck />,
  // },
];
export default routes;
