import React, { useState } from "react";
import InputField2 from "components/fields/InputField2";
import PhoneInputField from "components/fields/PhoneInputField";
import Card from "components/card";
import { useAuth } from "App";
import jwtDecode from "jwt-decode";
import { useMutation, useQuery } from "@apollo/client";
import { GET_USER_BY_ID } from "graphql/auth/queries";
import Loader from "components/Loader";
import { UPDATE_PROFILE } from "graphql/users/mutations";
import Notification from "components/Notification/Notification";
import VideoPlayer from "components/video";
import Video from "../portfolio/components/Video/video";
import AddDomainAnimation from "../portfolio/components/New/AddDomainAnimatio";

const MyAccount = () => {
  const { token } = useAuth();
  const user = jwtDecode(token);

  const {
    loading,
    // error,
    data,
    // refetch
  } = useQuery(GET_USER_BY_ID, {
    variables: { id: user.sub }, // Replace with the domain you want to query
  });
  const [updateProfile] = useMutation(UPDATE_PROFILE);

  const initialFormData = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    country: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [loaderOpen, setLoaderOpen] = useState(false);
  const [showNotification, setShowNotification] = useState({
    status: false,
    value: "",
  });

  // Update formData with data from the query when it's available
  React.useEffect(() => {
    if (!loading && data && data.getUserById) {
      const { first_name, last_name, phone_number, email, isEmailVerified, country } =
        data.getUserById;
      setFormData({
        firstName: first_name,
        lastName: last_name,
        phoneNumber: phone_number,
        email,
        country
      });
    }
  }, [loading, data]);

  const [errors, setErrors] = useState({
    email: "",
    phoneNumber: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleEditProfile = (e) => {
    e.preventDefault();
    setLoaderOpen(true);
    updateProfile({
      variables: {
        input: {
          id: user.sub,
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phoneNumber: formData.phoneNumber,
          country: formData.country,
        },
      },
    })
      .then((res) => {
        if (res.data) {
          console.log(res.data);
          setLoaderOpen(false);
          setShowNotification({
            status: true,
            value: "Profile Updated Successfully.",
          });
          setTimeout(() => {
            setShowNotification({ status: false, value: "" });
          }, 2000);
          // const verificationRecord = res.data.createTxtRecord.randomString;
          // console.log(verificationRecord)
          // setVerificationRecord(res.data.createTxtRecord.randomString);
          // setOpenVerificationConfirmationModal(true);
          // setVerificationStatus('pending');
        } else {
          throw new Error("Something went Wrong");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^[0-9]*$/;
    return phoneRegex.test(phoneNumber);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  };

  const handlePhoneNumberBlur = () => {
    if (!validatePhoneNumber(formData.phoneNumber)) {
      setErrors({
        ...errors,
        phoneNumber: "Invalid phone number",
      });
    } else {
      setErrors({
        ...errors,
        phoneNumber: "",
      });
    }
  };

  const handleEmailBlur = () => {
    if (!validateEmail(formData.email)) {
      setErrors({
        ...errors,
        email: "Invalid email address",
      });
    } else {
      setErrors({
        ...errors,
        email: "",
      });
    }
  };

  // if (loading) return  <Loader loaderOpen={true} setLoaderOpen={setLoaderOpen} />;
  // if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <Loader loaderOpen={loaderOpen} setLoaderOpen={setLoaderOpen} />
      {showNotification.status && (
        <Notification
          showNotification={showNotification}
          setShowNotification={setShowNotification}
        />
      )}
      <Card extra="items-center w-full h-full p-[16px] bg-cover">
        <div className="flex w-full flex-col items-center justify-between gap-12 md:flex-row xl:px-48">
          <div className="w-full max-w-[320px] flex-col items-center md:max-w-full md:pl-4 xl:max-w-[420px]">
            <InputField2
              variant="auth"
              extra="mb-3"
              label="First Name*"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleChange}
              name="firstName"
              id="first-name"
              type="text"
            />

            <InputField2
              variant="auth"
              extra="mb-3"
              label="Last Name*"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
              name="lastName"
              id="last-name"
              type="text"
            />

            <PhoneInputField
              variant="auth"
              extra="mb-3"
              label="Phone Number*"
              placeholder="Phone Number"
              value={formData.phoneNumber}
              valueCountry={formData.country}
              onChange={handleChange}
              onBlur={handlePhoneNumberBlur}
              name="phoneNumber"
              id="phone-number"
              type="tel"
            />
            {errors.phoneNumber && (
              <div className="mt-2 text-sm text-red-500">
                {errors.phoneNumber}
              </div>
            )}

            <InputField2
              variant="auth"
              extra="mb-3"
              label="Email*"
              placeholder="mail@simple.com"
              value={formData.email}
              onChange={handleChange}
              onBlur={handleEmailBlur}
              disabled
              name="email"
              id="email"
              type="email"
            />
            {errors.email && (
              <div className="mt-2 text-sm text-red-500">{errors.email}</div>
            )}

            <button
              className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              onClick={handleEditProfile}
            >
              Save
            </button>
          </div>
          <div className="w-full lg:w-1/2">
            <AddDomainAnimation />
          </div>
        </div>
      </Card>
      <div className="mt-6">
        <Video />
      </div>
    </>
  );
};

export default MyAccount;
