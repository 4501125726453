import React, { useEffect, useState } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client';
// import { APPROVE_DOMAIN_BY_ID_MUTATION } from 'graphql/products/mutations';
// import { GET_APPROVED_DOMAINS } from 'graphql/products/queries';
// import ApprovedDomainsTable from './components/ApprovedDomainsTable';
import { GET_LIVE_PRODUCTS_BY_VENDOR } from "graphql/products/queries";
import LiveDomainsByVendor from "./components/LiveDomainsByVendorTable";
import { useAuth } from "App";
import jwtDecode from "jwt-decode";
import { GET_USER_BY_ID } from "graphql/auth/queries";
import Video from "./components/Video/video";

export default function MyLiveDomains() {
    const { token } = useAuth();
    const user = jwtDecode(token);

    const [loaderOpen, setLoaderOpen] = useState(false);
    const [error, setError] = useState(null);
    // const [domains, setDomains] = useState([]);

    // Query for user data
    const [executeQueryGetUserById, { loading: loadingUser, error: errorUser, data: dataUser }] = useLazyQuery(GET_USER_BY_ID);
    const [executeQuery, { loading: loadingProducts, error: errorProducts, data: dataProducts }] = useLazyQuery(GET_LIVE_PRODUCTS_BY_VENDOR);

    useEffect(() => {

        executeQueryGetUserById({ variables: { "id": user.sub } })

    }, [])
    const merchant_id = dataUser?.getUserById.merchant_id
    useEffect(() => {

        if (merchant_id) {
            console.log(merchant_id);
            executeQuery({ variables: { "vendor": merchant_id } })
        }

    }, [merchant_id])

    if (loadingUser) return <p className='mt-8'>Loading user data...</p>;
    if (errorUser) return <p className='mt-8'>Error fetching user data: {errorUser.message}</p>;

    if (loadingProducts) return <p className='mt-8'>Loading live products...</p>;
    if (errorProducts) return <p className='mt-8'>Error fetching live products: {error.message}</p>;

    const domains = dataProducts?.getLiveProductsByVendor;

    console.log(domains);
    // }
    // const [approveDomain] = useMutation(APPROVE_DOMAIN_BY_ID_MUTATION);

    // var domains = null;
    // console.log("GraphQL Query:", APPROVE_DOMAIN_BY_ID_MUTATION?.loc?.source?.body, GET_NON_APPROVED_DOMAINS?.loc?.source?.body);

    // const handleApprove = (domainId) => {
    //     // Show a confirmation dialog (you can use a modal library like react-modal)
    //     const isConfirmed = window.confirm("Are you sure you want to approve this domain?");

    //     if (isConfirmed) {
    //         approveDomain({ variables: { id: domainId } })
    //             .then((result) => {
    //                 // Handle the success response
    //                 refetch(); 

    //                 console.log("Domain approved successfully:", result);
    //             })
    //             .catch((error) => {
    //                 // Handle any errors
    //                 console.error("Error approving domain:", error);
    //             });
    //     }
    // };

    // if (loading) return <p>Loading...</p>;
    // if (error) return <p>Error: {error.message}</p>;

    // domains = data.getLiveProductsByVendor;
    // console.log(data)

    // if (loadingUser) return <p className="mt-8">Loading user data...</p>;
    // if (errorUser)
        return (
            <div className="mt-5 grid h-full">
                <div className='overflow-auto'>
                    {/* <h1>All Domains</h1> */}
                    {domains && (
                        <>
                            <LiveDomainsByVendor domains={domains} />
                        </>
                    )}
                </div>
            </div>
        )
}
