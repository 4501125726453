import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const files = [
  {
    title: "1-3 Letter Domain",
    size: "3.9 MB",
    link: "https://brandcart.com/collections/3-letter-domain",
    source: "/Images/OurCollectionPhotos/1-3-Letter-Domain.jpg",
  },
  {
    title: "Agriculture & Farming",
    size: "3.9 MB",
    link: "https://brandcart.com/collections/agriculture-farming",
    source: "/Images/OurCollectionPhotos/Agriculture-and-Farming.jpg",
  },
  {
    title: "Artificial Intelligence, Bots & Chat",
    size: "3.9 MB",
    link: "https://brandcart.com/collections/artificial-intelligence-bots-chat",
    source: "/Images/OurCollectionPhotos/Artificial.jpg",
  },
  {
    title: "Automotive & Manufacturing",
    size: "3.9 MB",
    link: "https://brandcart.com/collections/automotive-manufacturing",
    source: "/Images/OurCollectionPhotos/Automotive_Manufacturing.jpg",
  },
  {
    title: "Catchy, Trendy & Creative Domains",
    size: "3.9 MB",
    link: "https://brandcart.com/collections/catchy-trendy-creative-domains",
    source: "/Images/OurCollectionPhotos/Catchy-Trendy-Domains.jpg",
  },
  {
    title: "Classic & Elegant Brand Names",
    size: "3.9 MB",
    link: "https://brandcart.com/collections/classic-elegant-brand-names",
    source: "/Images/OurCollectionPhotos/Classy-_-Elegant.jpg",
  },
  {
    title: "Extensions",
    size: "3.9 MB",
    link: "https://brandcart.com/collections/extensions",
    source: "/Images/OurCollectionPhotos/Extensions.jpg",
  },
  {
    title: "Food Delivery & Meal Kits and Diet",
    size: "3.9 MB",
    link: "https://brandcart.com/collections/food-delivery-meal-kits-and-diet",
    source: "/Images/OurCollectionPhotos/Food-Delivery-_-Meal-Kits.jpg",
  },
  {
    title: "Medical & Dental Clinics & Health centers",
    size: "3.9 MB",
    link: "",
    source: "/Images/OurCollectionPhotos/Medical-Dental-Clinic.jpg",
  },
  {
    title: "Popular Movie Based Brand Names",
    size: "3.9 MB",
    link: "https://brandcart.com/collections/popular-movie-based-brand-names",
    source: "/Images/OurCollectionPhotos/Popular-Movie.jpg",
  },
  {
    title: "Real Estate, Building and Construction",
    size: "3.9 MB",
    link: "https://brandcart.com/collections/real-estate-building-and-construction",
    source: "/Images/OurCollectionPhotos/Real-Estate.jpg",
  },
  {
    title: "Religious & Spiritual",
    size: "3.9 MB",
    link: "https://brandcart.com/collections/religious-spiritual",
    source: "/Images/OurCollectionPhotos/Religious-_-Spiritual.jpg",
  },
  {
    title: "Short & One Word Domains",
    size: "3.9 MB",
    link: "https://brandcart.com/collections/short-names",
    source: "/Images/OurCollectionPhotos/Short-_-One-Word-Domains.jpg",
  },
  {
    title: "Transportation & logistics",
    size: "3.9 MB",
    link: "https://brandcart.com/collections/transportation-logistics",
    source: "/Images/OurCollectionPhotos/Transportation-and-Logistics.jpg",
  },
  // ... (other files)
];

const CustomSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="px-12">
      <Slider {...settings}>
        {files.map((file) => (
          <div key={file.source} className="slide-item px-8 lg:py-12">
            <div className="item-content">
              <div className="group mx-auto   block flex items-center justify-center overflow-hidden rounded-full bg-gray-100 lg:h-64 lg:w-64">
              <Link to={file.link} >
                <img
                  src={file.source}
                  alt=""
                  className="pointer-events-none object-cover group-hover:opacity-75"
                />
                   </Link>
              </div>
              <Link to={file.link} className="text-center">
                <p className="pointer-events-none mt-2 text-center text-sm font-medium text-gray-900">
                  {file.title}
                </p>
              </Link>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CustomSlider;
