/* eslint-disable */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";

import { Disclosure } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import {
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
} from '@heroicons/react/24/outline'
import { useAuth } from "App";
import jwtDecode from "jwt-decode";
// chakra imports

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function SidebarLinks(props) {
  // Chakra color mode
  let location = useLocation();

  const { routes } = props;

  const { token } = useAuth()
  const user = jwtDecode(token);
  // console.log("Token hai Ji: ", token);
  // console.log("Logged In User hai ji: ", user);

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    // console.log(routeName);
    // console.log(location.pathname);
    return location.pathname.includes(routeName);
  };

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      // if (
      //   route.layout === "/admin" ||
      //   route.layout === "/auth" ||
      //   route.layout === "/rtl" ||
      //   route.layout === "/domain-ownership-check"
      // ) {
      return (
        <>


          {!route.children ? (
            <>
              {!route.name || route.name === '' ? null : (
                <Link key={index} to={route.layout + "/" + route.path}>
                  <div className="relative mb-3 flex hover:cursor-pointer">
                    <li
                      className="my-[3px] flex cursor-pointer items-center px-8"
                      key={index}
                    >
                      <span
                        className={`${activeRoute(route.path) === true
                          ? "font-bold text-brand-500 dark:text-white"
                          : "font-medium text-gray-600"
                          }`}
                      >
                        {route.icon ? route.icon : <DashIcon />}{" "}
                      </span>
                      <p
                        className={`leading-1 ml-4 flex ${activeRoute(route.path) === true
                          ? "font-bold text-navy-700 dark:text-white"
                          : "font-medium text-gray-600"
                          }`}
                      >
                        {route.name}
                      </p>
                    </li >
                    {activeRoute(route.path) ? (
                      <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
                    ) : null
                    }
                  </div >
                </Link >
              )}
            </>
            // <a
            //   href={route?.href}
            //   className={classNames(
            //     route?.current ? 'bg-gray-50' : 'hover:bg-gray-50',
            //     'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-700'
            //   )}
            // >
            //   {/* <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" /> */}
            //   {route?.name}
            // </a>
          ) : (
            <>
              {route.name === '' || !route.name ? null : (
                <Disclosure as="div">
                  {({ open }) => (
                    <div key={index} className="relative mb-3 hover:cursor-pointer">
                      <li
                        key={index}
                      >
                        <div
                          className="flex items-center"
                        >
                          {(!route.children) ? (
                            <Link
                              to={route.layout + "/" + route.path}
                              className="my-[3px] flex cursor-pointer items-center px-8"
                            >
                              <span
                                className={`${open === true
                                  ? "font-bold text-brand-500 dark:text-white"
                                  : "font-medium text-gray-600"
                                  }`}
                              >
                                {route.icon ? route.icon : <DashIcon />}{" "}
                              </span>
                              <p
                                className={`leading-1 ml-4 flex ${open === true
                                  ? "font-bold text-navy-700 dark:text-white"
                                  : "font-medium text-gray-600"
                                  } whitespace-nowrap overflow-hidden overflow-ellipsis`}
                              >
                                {route.name}
                              </p>
                            </Link>
                          ) : (
                            <div
                              className="my-[3px] flex cursor-pointer items-center px-8"
                            >
                              <span
                                className={`${open === true
                                  ? "font-bold text-brand-500 dark:text-white"
                                  : "font-medium text-gray-600"
                                  }`}
                              >
                                {route.icon ? route.icon : <DashIcon />}{" "}
                              </span>
                              <p
                                className={`leading-1 ml-4 flex ${open === true
                                  ? "font-bold text-navy-700 dark:text-white"
                                  : "font-medium text-gray-600"
                                  } whitespace-nowrap overflow-hidden overflow-ellipsis`}
                              >
                                {route.name}
                              </p>
                            </div>
                          )}
                          <div className="w-full flex justify-end">
                            <Disclosure.Button
                              className={classNames(
                                route?.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                'flex items-center text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700'
                              )}
                            >
                              {/* <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" /> */}
                              {/* {route?.name} */}
                              <ChevronRightIcon
                                className={classNames(
                                  open ? 'rotate-90 text-gray-500' : 'text-gray-400',
                                  'ml-auto h-5 w-5 shrink-0'
                                )}
                                aria-hidden="true"
                              />
                            </Disclosure.Button>
                          </div>
                        </div>
                        {/* 44px */}
                        <Disclosure.Panel as="ul" className="mt-1 px-2 ml-12">
                          {
                            route.children.map((subItem) => {
                              // console.log(subItem);
                              // console.log(route)
                              // console.log(open)
                              return (
                                <div key={subItem.name}>
                                  {
                                    subItem.name === '' || !subItem.name ? null : (
                                      subItem.accessRoles.some(role => user.roles.includes(role)) ? (
                                        <li>
                                          <Link
                                            to={route.layout + subItem.layout + "/" + subItem.path}
                                            className={classNames(
                                              location.pathname.includes(route.layout + subItem.layout + "/" + subItem.path) ? 'bg-gray-50' : 'hover:bg-gray-50',
                                              'block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-700'
                                            )}
                                          >
                                            {/* <pre>User Roles: {JSON.stringify(user.roles)}</pre>
                                          <pre>Access Roles: {JSON.stringify(subItem.accessRoles)}</pre> */}
                                            {/* <Disclosure.Button
                                            as="a"
                                            href={subItem?.href}
                                            className={classNames(
                                              subItem.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                              'block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-700'
                                            )}
                                          > */}
                                            {subItem.name}
                                            {/* </Disclosure.Button> */}
                                          </Link>
                                        </li>
                                      ) : null
                                    )
                                  }
                                </div>
                              )
                            })
                          }
                        </Disclosure.Panel>
                      </li>
                      {open ? (
                        <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
                      ) : null
                      }
                    </div>
                  )}
                </Disclosure>
              )}
            </>
          )
          }
        </>
      );
      // }
    });
  };
  // BRAND
  return createLinks(routes);
}

export default SidebarLinks;
