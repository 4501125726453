const people = [
  {
    name: "Exclusive offers on 1-3 letter Brand Domains",
    email:
      "Brandcart offers the shortest 1-3 letter domain brand - highly impactful and easy to remember.",
    imageUrl:
      "/Images/123-image.webp"
  },
  {
    name: "Logonama— creative logo for all orders above $1000.",
    email:
      "Brandcart engages design experts to create a custom logo showcasing various brand mockups.",
    imageUrl:"/Images/Logonama.webp"
  },
];

export default function Cards() {
  return (
    <div className="grid mt-8 lg:mt-0 px-4 grid-cols-1 gap-8 lg:grid-cols-2 lg:py-24">
      {people.map((person) => (
        <div
          key={person.email}
          className="relative flex items-center shadow-lg space-x-5 rounded-lg border border-gray-300 bg-white px-6 py-5  focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
        >
          <div className="flex-shrink-0">
            <img
              className="h-24 w-24 rounded-full"
              src={person.imageUrl}
              alt=""
            />
          </div>
          <div className="min-w-0 flex-1">
              <span className="absolute inset-0" aria-hidden="true" />
              <p className="text-lg font-medium text-[#01afef]">{person.name}</p>
              <p className=" text-md text-gray-500 mt-2">{person.email}</p>
          </div>
        </div>
      ))}
    </div>
  );
}
