import { Link } from "react-router-dom";

const features = [
  {
    name: "PREMIUM BRAND DOMAINS",
    description:
      "Exquisite premium brandable names for the 21st century businesses.",
    imageSrc: "/Images/premium.png",
    button: "Shop Premium",
    link: "https://brandcart.com/collections/premium-domains-for-sale",
    color: "text-[#d09239]",
    imageAlt:
      "White canvas laptop sleeve with gray felt interior, silver zipper, and tan leather zipper pull.",
  },

  {
    name: "ULTRA PREMIUM BRAND DOMAINS",
    description:
      "Top-notch ultra premium domain brands for those who dream big.",
    imageSrc: "/Images/luxury.png",
    button: "Shop Ultra Premium",
    link: "https://brandcart.com/collections/ultra-premium-domain-brands",
    color: "text-[#723648]",

    imageAlt:
      "White canvas laptop sleeve with gray felt interior, silver zipper, and tan leather zipper pull.",
  },
  {
    name: "ONE WORD BRANDS",
    description:
      "Brand Enthusiasts curated highly brandable Domains for your business.",
    imageSrc: "/Images/oneword.png",
    button: "Shop Brands",
    link: "https://brandcart.com/collections/short-names",
    color: "text-[#26c1ce]",

    imageAlt:
      "White canvas laptop sleeve with gray felt interior, silver zipper, and tan leather zipper pull.",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Threecards() {
  return (
    <div className="bg-white pb-32">
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-6 px-4 sm:px-6 sm:py-2 md:grid-cols-1 lg:grid-cols-3 lg:max-w-7xl lg:px-0">
        {features.map((feature, index) => (
          <div key={index}>
            <div className="relative flex justify-center">
              <img  src={feature.imageSrc} />

              <div className="absolute w-full top-0 flex flex-col gap-4 p-6 md:w-[446px] lg:w-full">
                <h3 className="text-white">{feature.name}</h3>
                <p className="text-white">{feature.description}</p>

                <Link
                  to={feature.link}
                  target="_blank"
                  className={`w-max rounded-sm bg-white p-3 lg:p-2 xl:p-3 ${feature.color}`}
                >
                  {feature.button}
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
