import Card from "components/card";
import BarChart from "components/charts/BarChart";
import {
    // barChartDataWeeklyRevenue,
    barChartOptionsWeeklyRevenue,
} from "variables/analytics";
// import { MdBarChart } from "react-icons/md";

const LastWeekCityWise = ({ barChartDataWeeklyCityWise, citiesWeekWise }) => {
    console.log(barChartDataWeeklyCityWise, citiesWeekWise)
    // console.log(barChartDataWeeklyRevenue)
    // barChartDataWeeklyRevenue[0].data = [100, 100, 100, 100, 90, 90, 90];
    // barChartDataWeeklyRevenue[0].name = 'Brandcart.com'
    const tempCatArr = barChartOptionsWeeklyRevenue;
    tempCatArr.xaxis.categories = citiesWeekWise;
    // console.log(tempCatArr)
    return (
        <Card extra="flex flex-col bg-white w-full rounded-3xl py-6 px-2 text-center">
            <div className="mb-auto flex items-center justify-between px-6">
                <h2 className="text-lg font-bold text-navy-700 dark:text-white">
                    Top Cities Visits Last Week
                </h2>
                {/* <button className="!linear z-[1] flex items-center justify-center rounded-lg bg-lightPrimary p-2 text-brand-500 !transition !duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
                    <MdBarChart className="h-6 w-6" />
                </button> */}
            </div>

            <div className="md:mt-16 lg:mt-0">
                <div className="h-[250px] w-full xl:h-[350px] flex items-center justify-center">
                    {(barChartDataWeeklyCityWise[0].data.length > 0) ? (
                        <BarChart
                            chartData={barChartDataWeeklyCityWise}
                            chartOptions={tempCatArr}
                        />
                    ) : <div className="">No Data found for this Domain</div>}
                </div>
            </div>
        </Card>
    );
};

export default LastWeekCityWise;
