import { createContext, useState } from "react"

const RegisterFormContext = createContext({})

export const RegisterFormProvider = ({ children }) => {


    const title = {
        0: 'Register Step 1',
        1: 'Register Step 2',
    }

    const [page, setPage] = useState(0)
    // const [verificationFinalStatusData, setVerificationFinalStatusData] = useState([]);

    const [data, setData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        // username: '',
        password: '',
        phoneNumber: '',
        country: 'US',
        // company: '',
        // addressLine1: '',
        // addressLine2: '',
        // role: ''
    })


    // useEffect(() => {
    //     if (data.sameAsBilling) {
    //         setData(prevData => ({
    //             ...prevData,
    //             shipFirstName: prevData.billFirstName,
    //             shipLastName: prevData.billLastName,
    //             shipAddress1: prevData.billAddress1,
    //             shipAddress2: prevData.billAddress2,
    //             shipCity: prevData.billCity,
    //             shipState: prevData.billState,
    //             shipZipCode: prevData.billZipCode
    //         }))
    //     } else {
    //         setData(prevData => ({
    //             ...prevData,
    //             shipFirstName: "",
    //             shipLastName: "",
    //             shipAddress1: "",
    //             shipAddress2: "",
    //             shipCity: "",
    //             shipState: "",
    //             shipZipCode: ""
    //         }))
    //     }
    // }, [data.sameAsBilling])

    // const handleProductDescriptionChange = e => {
    //     setData(prevData => ({
    //         ...prevData,
    //         description: 
    //     }))
    // }
    // const [description, setDescription] = useState('');


    const handleChange = e => {
        // console.log("data");
        // console.log(e);
        const type = e.target?.type
        // console.log(type);

        const name = e.target?.name
        // console.log(name);
        const value = type === "checkbox"
            ? e.target.checked
            : (name && name === "price") ? parseFloat(e.target.value) : e.target.value;

        // console.log(type, name, value)

        setData(prevData => ({
            ...prevData,
            [name]: value
        }))
        console.log(data);
    }

    const {
        // billAddress2,
        // sameAsBilling,
        // shipAddress2,
        // optInNews,
        ...requiredInputs } = data

    console.log(data);

    const canSubmit = [...Object.values(requiredInputs)].every(Boolean) && page === Object.keys(title).length - 1

    const canNextPage1 = Object.keys(data)
        .filter(key =>
            key.startsWith('first') ||
            key.startsWith('last')
        )
        .map(key => {
            // console.log(key, data[key]);
            return data[key];
        })
        .every(Boolean)


    // const canNextPage2 = Object.keys(data)
    //     .filter(key => key.startsWith('ship') && key !== 'shipAddress2')
    //     .map(key => data[key])
    //     .every(Boolean)

    const disablePrev = page === 0

    const disableNext =
        (page === Object.keys(title).length - 1)
        || (page === 0 && !canNextPage1)
    // || (page === 1 && !canNextPage2)

    const prevHide = page === 0 && "remove-button"

    const nextHide = page === Object.keys(title).length - 1 && "remove-button"

    const submitHide = page !== Object.keys(title).length - 1 && "remove-button"

    return (
        <RegisterFormContext.Provider value={{ title, page, setPage, data, setData, canSubmit, handleChange, disablePrev, disableNext, prevHide, nextHide, submitHide }}>
            {children}
        </RegisterFormContext.Provider>
    )
}

export default RegisterFormContext 