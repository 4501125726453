import React, { useState } from "react";
import { useMutation } from "@apollo/client";
// import { APPROVE_DOMAIN_BY_ID_MUTATION } from 'graphql/products/mutations';
// import { GET_APPROVED_DOMAINS } from 'graphql/products/queries';
import { GET_DOMAINS_BY_USER_ID } from "graphql/products/mutations";
import UserDomainsTable from "./components/UserDomainsTable";
import jwtDecode from "jwt-decode";
import Video from "./components/Video/video";
// import { useAuth } from 'App';

export default function MyDomains() {
  // console.log("hello")
  // const { loading, error, data, refetch } = useQuery(GET_APPROVED_DOMAINS);

  const [domains, setDomains] = useState([]);

  const [getDomainsByUserId] = useMutation(GET_DOMAINS_BY_USER_ID);

  // const token = useAuth();
  // console.log("GraphQL Query:", APPROVE_DOMAIN_BY_ID_MUTATION?.loc?.source?.body, GET_NON_APPROVED_DOMAINS?.loc?.source?.body);
  const user = jwtDecode(localStorage.getItem("token"));
  // console.log("User: ", user);
  // const handleApprove = (domainId) => { }
  // Show a confirmation dialog (you can use a modal library like react-modal)
  // const isConfirmed = window.confirm("Are you sure you want to approve this domain?");

  // if (isConfirmed) {
  getDomainsByUserId({ variables: { id: user.sub } })
    .then((result) => {
      // Handle the success response
      // refetch();

      // console.log("Domain approved successfully:", result);
      if (result.data && result.data.findDomainsByUserId) {
        const dataArray = Object.values(result.data.findDomainsByUserId);
        // console.log(dataArray);
        const filteredArray = dataArray.filter(
          (item) => item.status === "Not-Approved"
        );
        // console.log(filteredArray);
        setDomains(filteredArray);
      } else {
        throw new Error("No data found");
      }
    })
    .catch((error) => {
      // Handle any errors
      console.error("Error approving domain:", error);
    });
  //     }
  // };

  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error: {error.message}</p>;

  // domains = data.findNonApprovedDomains;

  return (
    <div className="mt-5 grid h-full">
      <div className="overflow-auto">
        <UserDomainsTable domains={domains} />
      </div>
      <div className="mt-6">
        <Video />
      </div>
    </div>
  );
}
