import React from 'react'
import { useQuery } from '@apollo/client';
// import { APPROVE_DOMAIN_BY_ID_MUTATION } from 'graphql/products/mutations';
import { GET_APPROVED_DOMAINS } from 'graphql/products/queries';
// import ApprovedDomainsTable from './components/ApprovedDomainsTable';

export default function AllDomains() {

    const { loading, error, data } = useQuery(GET_APPROVED_DOMAINS);
    // const [approveDomain] = useMutation(APPROVE_DOMAIN_BY_ID_MUTATION);

    // var domains = null;
    // console.log("GraphQL Query:", APPROVE_DOMAIN_BY_ID_MUTATION?.loc?.source?.body, GET_NON_APPROVED_DOMAINS?.loc?.source?.body);

    // const handleApprove = (domainId) => {
    //     // Show a confirmation dialog (you can use a modal library like react-modal)
    //     const isConfirmed = window.confirm("Are you sure you want to approve this domain?");

    //     if (isConfirmed) {
    //         approveDomain({ variables: { id: domainId } })
    //             .then((result) => {
    //                 // Handle the success response
    //                 refetch(); 
                    
    //                 console.log("Domain approved successfully:", result);
    //             })
    //             .catch((error) => {
    //                 // Handle any errors
    //                 console.error("Error approving domain:", error);
    //             });
    //     }
    // };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    // domains = data.findNonApprovedDomains;

    return (
        <div className="mt-5 grid h-full">
            <div className='overflow-auto'>
                <h1>All Domains</h1>
                {/* <ApprovedDomainsTable domains={domains} /> */}
            </div>
        </div>
    )
}