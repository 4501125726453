import Footer from "components/footer/FooterAuthDefault";
// import authImg from "assets/img/auth/auth.png";
import { Link, Routes, Route, Navigate } from "react-router-dom";
import routes from "routes.js";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import { RegisterFormProvider } from "context/RegisterFormContext";
import { ForgotPasswordFormProvider } from "context/ForgotPasswordFormContext";
import BrandCartLogo from "./../../assets/img/brandcart/brandcart_logo.webp";
import NewFooter from "components/footer/NewFooter";
import Cards from "components/Cards/Card";
import FourCards from "components/Cards/FourCards";
import Slider from "components/Cards/Slider";
import LeftRight from "components/Cards/LTR";
import Threecards from "components/Cards/Threecards";
// import useRegisterFormContext from "hooks/useRegisterFormContext";

export default function Auth() {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        if (prop.path === "register" || prop.path === "sign-in") {
          return (
            <Route path={`/${prop.path}`} element={<RegisterFormProvider>{prop.component}</RegisterFormProvider>} />
          );
        } else if (prop.path === "forgot-password") {
          // Another context provider for a different route
          return (
            <Route path={`/${prop.path}`} element={<ForgotPasswordFormProvider>{prop.component}</ForgotPasswordFormProvider>} />
          );
        } else if (prop.path === "verify-token") {
          return (
            <Route path={`/${prop.path}`} element={prop.component} />
          );
        }
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  return (
    <div>
      <div className="relative float-right h-full min-h-full w-full !bg-white dark:!bg-navy-900">
        {/* <FixedPlugin /> */}
        <main className={`mx-auto min-h-full`}>
          <div className="relative flex">
            <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%]  lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:max-w-[1576px] xl:px-0 ">
              <div className="mb-auto flex flex-col pl-5 pr-5 md:pl-12 md:pr-0  lg:pl-0 xl:max-w-full">
                <Link
                  to="/admin"
                  className="mt-0 flex w-full justify-start lg:pt-20"
                >
                  <div className="mx-auto flex h-fit w-fit items-center hover:cursor-pointer">

                    <img className="w-60" src={BrandCartLogo} alt="BrandCart" />

                    {/* <svg
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.70994 2.11997L2.82994 5.99997L6.70994 9.87997C7.09994 10.27 7.09994 10.9 6.70994 11.29C6.31994 11.68 5.68994 11.68 5.29994 11.29L0.709941 6.69997C0.319941 6.30997 0.319941 5.67997 0.709941 5.28997L5.29994 0.699971C5.68994 0.309971 6.31994 0.309971 6.70994 0.699971C7.08994 1.08997 7.09994 1.72997 6.70994 2.11997V2.11997Z"
                        fill="#A3AED0"
                      />
                    </svg>
                    <p className="ml-3 text-sm text-gray-600">
                      Back to Dashboard
                    </p> */}
                  </div>
                </Link>
                <Routes>
                  {getRoutes(routes)}
                  <Route
                    path="/"
                    element={<Navigate to="/auth/sign-in" replace />}
                  />
                </Routes>
                {/* <div className="absolute right-0 hidden h-full min-h-screen md:block lg:w-[49vw] 2xl:w-[44vw]">
                  <div
                    className="absolute flex h-full w-full items-end justify-center bg-cover bg-center lg:rounded-bl-[120px] xl:rounded-bl-[200px]"
                    style={{ backgroundImage: `url(${authImg})` }}
                  />
                </div> */}
              </div>
              <Threecards />
              <LeftRight />
              <Slider />
              <Cards />

              <div className="py-12">
                <FourCards />
              </div>

              {/* <Footer /> */}
            </div>
          </div>
          <NewFooter />
        </main>
      </div>
    </div>
  );
}
