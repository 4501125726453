import { gql } from "@apollo/client";

export const CREATE_NOTIFICATION_MUTATION = gql`
  mutation CreateNotification($userId:Int!,$title:String!,$message:String!){
    createNotification(userId:$userId,title:$title,message:$message) {
        userId
        title
        message
        createdAt
        id
    }
  }
`;