import React from "react";
import { useFilters, usePagination, useSortBy, useTable } from "react-table";
import CardMenu from "components/card/CardMenu";
// import Checkbox from "components/checkbox";
import Card from "components/card";
import {
  ChevronDoubleRightIcon,
  ChevronDoubleLeftIcon,
} from "@heroicons/react/24/solid";

const UserDomainsTable = ({ domains }) => {
  // Define the columns based on your data structure
  const columns = React.useMemo(
    () => [
      {
        Header: "Domain",
        accessor: "word",
      },
      {
        Header: "Buy Now Price",
        accessor: "buyNowPrice",
      },
      {
        Header: "Floor Price",
        accessor: "floorPrice",
      },
      {
        Header: "Minimum Offer",
        accessor: "minimumOffer",
      },
      {
        Header: "Action",
        Cell: ({ row }) => (
          <div className="" onClick={() => {}}>
            Pending For Approval
          </div>
        ),
      },
    ],
    []
  );

  const data = React.useMemo(() => domains, [domains]);
  // console.log(data);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: {
      pageIndex,
      // pageSize,
      filters,
      selectedRowIds,
    },
  } = tableInstance;

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Not Approved Domains
        </div>

        <CardMenu />
      </header>

      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
        <table
          className="w-full"
          variant="simple"
          color="gray-500"
          mb="24px"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, columnIndex) => (
                  <th
                    className="border-b border-gray-200 pb-[10px] pr-8 text-start dark:!border-navy-700"
                    {...column.getHeaderProps(column.getSortByToggleProps())} // Updated line
                    key={columnIndex}
                  >
                    <div>
                      {column.render("Header")}
                      <span className="text-xs">
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " ▼"
                            : " ▲"
                          : " ▲▼"}
                      </span>
                    </div>
                    {/* {column.render('Filter')} */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="divide-y divide-gray-200" {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      className="pb-[16px] pt-[14px] sm:text-[14px]"
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        {rows.length === 0 && (
          <tr className="flex h-32  w-full items-center justify-center font-semibold text-red-600">
            No data found
          </tr>
        )}
      </div>
      <div className="mt-8 flex items-center justify-between pb-4">
        <div className="flex items-center justify-between gap-1 md:mt-8 md:pb-4">
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="flex cursor-pointer items-center rounded-lg bg-[#01afef] px-4 py-2 text-sm font-medium text-white"
          >
            <ChevronDoubleLeftIcon className="h-4 w-4 text-white" /> Previous
          </button>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="flex cursor-pointer items-center rounded-lg bg-[#01afef] px-4 py-2 text-sm font-medium text-white"
          >
            Next <ChevronDoubleRightIcon className="h-4 w-4 text-white" />
          </button>
        </div>
        <div>
          Page <strong>{pageIndex + 1}</strong> of{" "}
          <strong>{pageOptions.length}</strong>
        </div>
      </div>
    </Card>
  );
};

export default UserDomainsTable;
