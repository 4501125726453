import React from "react";
import Lottie from "lottie-react";
import animation1 from "./AddDomainAnimation.json";

const AddDomainAnimation = () => {
  return (
    <div>
      <Lottie
        animationData={animation1}
        className="relative z-[5] h-[100%] w-[100%]"
      />
    </div>
  )
};

export default AddDomainAnimation;


