import React, { useState } from 'react';
import InputField2 from "components/fields/InputField2";
// import { FcGoogle } from "react-icons/fc";
// import Checkbox from "components/checkbox";
import useForgotPasswordFormContext from 'hooks/useForgotPasswordFormContext';
import { Link } from 'react-router-dom';

function ForgotPasswordEmail() {

    const { data, handleChange } = useForgotPasswordFormContext()

    // Define errors state with initial values
    const [errors, setErrors] = useState({
        email: '',
    });

    const validateEmail = (email) => {
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        return emailRegex.test(email);
    };

    const handleEmailBlur = () => {
        if (!validateEmail(data.email)) {
            setErrors({
                ...errors,
                email: 'Invalid email address',
            });
        } else {
            setErrors({
                ...errors,
                email: '', // Clear the error when it's valid
            });
        }
    };

    return (

        <div className="mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:items-center lg:justify-start">
            {/* Sign in section */}
            <div className="w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                    Reset Password
                </h4>
                <p className="mb-9 ml-1 text-base text-gray-600">
                    Enter your details to Reset Password
                </p>
                <InputField2
                    variant="auth"
                    extra="mb-3"
                    label="Email*"
                    placeholder="example@gmail.com"
                    value={data.email}
                    onChange={handleChange}
                    onBlur={handleEmailBlur}
                    name="email"
                    id="email"
                    type="text"
                />
                {errors.email && <div className="text-red-500 text-sm mt-2">{errors.email}</div>}
                {/* <div className="mt-4">
                    <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
                        Already Have an Acccount?
                    </span>
                    <Link
                        to={"/auth/sign-in"}
                        className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                    >
                        Sign In
                    </Link>
                </div> */}
            </div>
        </div>
    )
}

export default ForgotPasswordEmail
