import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client';
import { GET_DOMAINS_BY_USER_ID } from 'graphql/products/mutations';
import ApprovedDomainsTable from './components/ApprovedDomainsTable';
import jwtDecode from 'jwt-decode';
import { useAuth } from 'App';
import Card from 'components/card';
import VideoPlayer from 'components/video';
import Video from './components/Video/video';

export default function MyApprovedDomains() {

    const [domains, setDomains] = useState([]);
    const { token } = useAuth();
    const user = jwtDecode(token);

    const [getDomainsByUserId, { loading, error }] = useMutation(GET_DOMAINS_BY_USER_ID);
    useEffect(() => {
        getDomainsByUserId({
            variables: {
                "id": user.sub
            }
        }).then((result) => {
            if (result.data && result.data.findDomainsByUserId) {
                const filteredProducts = result.data.findDomainsByUserId.filter(product => product.status === 'Approved');
                setDomains(filteredProducts);
                console.log(filteredProducts);
            }
        }).catch(error => {
            console.log("Error: ", error.message)
        })
    }, [])

    // const [approveDomain] = useMutation(APPROVE_DOMAIN_BY_ID_MUTATION);

    // var domains = null;
    // console.log("GraphQL Query:", APPROVE_DOMAIN_BY_ID_MUTATION?.loc?.source?.body, GET_NON_APPROVED_DOMAINS?.loc?.source?.body);

    // const handleApprove = (domainId) => {
    //     // Show a confirmation dialog (you can use a modal library like react-modal)
    //     const isConfirmed = window.confirm("Are you sure you want to approve this domain?");

    //     if (isConfirmed) {
    //         approveDomain({ variables: { id: domainId } })
    //             .then((result) => {
    //                 // Handle the success response
    //                 refetch(); 

    //                 console.log("Domain approved successfully:", result);
    //             })
    //             .catch((error) => {
    //                 // Handle any errors
    //                 console.error("Error approving domain:", error);
    //             });
    //     }
    // };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    // domains = data.findDomainsByUserId;

    return (
        <div className="mt-5 grid h-full ">
            <div className='overflow-auto'>
                <ApprovedDomainsTable domains={domains} />
            </div>
            <br></br>
            <Video/>
            {/* <Card>
                <div className='flex flex-col p-4 w-full lg:w-1/2 items-center md:p-6'>
                    <main className={`mx-[12px] h-full flex-none transition-all md:pr-2`}>
                        <div className="mt-2">
                            <p className="">Unlock your potential with our engaging video demos.</p>
                            <p className="">Expert guides for a seamless journey on your dashboard!</p>
                            <p className="mt-5 mb-4">Happy Selling!</p>
                            <VideoPlayer />
                        </div>
                    </main>
                </div>
            </Card> */}
            
        </div>
    )
}