import { gql } from "@apollo/client";

export const CREATE_PRODUCT_MUTATION = gql`
  mutation CreateProducts($createProductInput: [CreateDomainProductInput!]!) {
    createProducts(products: $createProductInput) {
      rawDomainsList
      word
      value
      checked
      buyNowPrice
      floorPrice
      minimumOffer
    }
  }
`;

export const APPROVE_DOMAIN_BY_ID_MUTATION = gql`
mutation ApproveDomain($id: Int!) {
    approveDomain(id:$id){
        id
        word
        value
        checked
        buyNowPrice
        floorPrice
        minimumOffer
        status
    }
}`

export const APPROVE_DOMAINS_BY_IDS_MUTATION = gql`
  mutation ApproveDomains($ids:[Int!]!, $notes:String!) {
    approveDomains(ids:$ids, notes:$notes) {
      id
      rawDomainsList
      word
      value
      checked
      buyNowPrice
      floorPrice
      minimumOffer
      status
      createdBy {
        first_name
      }
      isVerified
      createdAt
    }
  }
`

export const REJECT_DOMAINS_BY_IDS_MUTATION = gql`
  mutation RejectDomains($ids:[Int!]!, $notes:String!) {
    rejectDomains(ids:$ids, notes:$notes) {
      id
      rawDomainsList
      word
      value
      checked
      buyNowPrice
      floorPrice
      minimumOffer
      status
      createdBy {
        first_name
      }
      isVerified
      createdAt
    }
  }
`

export const MARK_PRODUCT_VERIFIED = gql`
  mutation MarkProductVerified($markVerifiedInput: MarkVerifiedInput!){
    markProductVerified(markVerifiedInput: $markVerifiedInput) {
      id
      isVerified
    }
  }
`



export const GET_DOMAIN_BY_ID = gql`
  mutation FindDomainById($id: Int!) {
    findDomainById(id: $id){
      id
      rawDomainsList
      word
      value
      checked
      buyNowPrice
      floorPrice
      minimumOffer
      status
    }
  }
`

export const GET_DOMAINS_BY_USER_ID = gql`
  mutation FindDomainByUserId($id:Int!){
    findDomainsByUserId(id:$id) {
      id
      rawDomainsList
      word
      value
      checked
      buyNowPrice
      floorPrice
      minimumOffer
      status
      isVerified
    }
  }
`