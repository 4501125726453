import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import Portfolio from "layouts/portfolio";
// import AddDomain from "layouts/portfolio/add/index";
import AuthLayout from "layouts/auth";
import { useAuth } from "App";
import EmailVerificationLayout from "layouts/email-verification";
// import DomainOwnershipCheck from "layouts/domain-ownership-check/DomainOwnershipCheck";
// import Domain from "layouts/domain/Domain";

// Define the initial state and reducer for managing authentication state
// const initialState = {
//   token: localStorage.getItem('token') || '', // Retrieve token from storage
// };

// const reducer = (state, action) => {
//   switch (action.type) {
//     case 'LOGIN':
//       localStorage.setItem('token', action.payload); // Store token in storage
//       return { ...state, token: action.payload };
//     case 'LOGOUT':
//       localStorage.removeItem('token'); // Remove token from storage
//       return { ...state, token: '' };
//     default:
//       return state;
//   }
// };

// const AuthContext = createContext();

// export const AuthProvider = ({ children, onLogin }) => {
//   return <AuthContext.Provider value={{ onLogin }}>{children}</AuthContext.Provider>;
// };

// export const useAuth = () => {
//   return useContext(AuthContext);
// };

const AppRouter = () => {

  // const [state, dispatch] = useReducer(reducer, initialState);

  // const handleLogin = newToken => {
  //   dispatch({ type: 'LOGIN', payload: newToken });
  // };

  // const handleLogout = () => {
  //   dispatch({ type: 'LOGOUT' });
  // };

  const { token } = useAuth();

  return (
    <Routes>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route path="email-verification/*" element={<EmailVerificationLayout />} />
      {/* <Route path="domain/*" element={<Domain />} /> */}
      <Route path="admin/*" element={token ? <AdminLayout /> : <Navigate to="/auth/sign-in" />} />
      <Route path="portfolio/*" element={token ? <Portfolio /> : <Navigate to="/auth/sign-in" />} />
      {/* <Route path="rtl/*" element={<RtlLayout />} />
      <Route path="domain-ownership-check/*" element={<DomainOwnershipCheck />} /> */}
      <Route path="/" element={<Navigate to="/admin" replace />} />
    </Routes>
  );
};

export default AppRouter;
