import useForgotPasswordFormContext from "hooks/useForgotPasswordFormContext"
import ForgotPasswordOTP from "./ForgotPasswordOTP"
import ForgotPasswordEmail from "./ForgotPasswordEmail"
import ForgotPasswordSetPassword from "./ForgotPasswordSetPassword"
// import BrandCartLogo from './../../../assets/img/brandcart/brandcart_logo.webp'

const ForgotPasswordFormInputs = () => {

    const { page } = useForgotPasswordFormContext()
    // console.log(page);
    const display = {
        0: <ForgotPasswordEmail />,
        1: <ForgotPasswordOTP />,
        2: <ForgotPasswordSetPassword />,
    }

    const content = (
        <div>
            <div className="form-inputs flex-col">
                {display[page]}
            </div>
        </div>
    )


    return content
}
export default ForgotPasswordFormInputs