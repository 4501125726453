const features = [
  {
    name: "Brandcart’s Mission",
    description:
      "We are a company (part of Virtuos Digital) with the explicit mission to change the world by creating differentiated and powerful brand strategies for our customers.",
    imageSrc: "/Images/brandcart_mission.webp",
    imageAlt:
      "White canvas laptop sleeve with gray felt interior, silver zipper, and tan leather zipper pull.",
  },
  {
    name: "Brandcart—Making a difference.",
    description:
      "Making a difference has become essential to building a brand. Consumers are shopping their values, and businesses are rethinking their value propositions. ",
    imageSrc: "/Images/Brandcart_difference.webp",
    imageAlt: "Detail of zipper pull with tan leather and silver rivet.",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function LeftRight() {
  return (
    <div className="bg-white mb-12 md:pb-32">
      <div className="mx-auto max-w-2xl px-4  sm:px-6 sm:py-2 lg:max-w-7xl lg:px-8">
        <div className=" space-y-16">
          {features.map((feature, featureIdx) => (
            <div
              key={feature.name}
              className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center lg:gap-x-8"
            >
              <div
                className={classNames(
                  featureIdx % 2 === 0
                    ? "lg:col-start-1"
                    : "lg:col-start-8 xl:col-start-9",
                  "mt-6 lg:col-span-5 lg:row-start-1 lg:mt-0 xl:col-span-4"
                )}
              >
                <h3 className="text-xl font-medium text-[#01afef]">
                  {feature.name}
                </h3>
                <p className="mt-2 text-md text-gray-500">
                  {feature.description}
                </p>
              </div>
              <div
                className={classNames(
                  featureIdx % 2 === 0
                    ? "lg:col-start-6 xl:col-start-5"
                    : "lg:col-start-1",
                  "flex-auto lg:col-span-7 lg:row-start-1 xl:col-span-8"
                )}
              >
                <div className="aspect-h-2 aspect-w-5 flex justify-center overflow-hidden rounded-lg bg-white">
                  <img
                    src={feature.imageSrc}
                    alt={feature.imageAlt}
                    className="object-cover object-center rounded-lg"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
