import React, { useState } from 'react';
import InputField2 from "components/fields/InputField2";
// import { FcGoogle } from "react-icons/fc";
// import Checkbox from "components/checkbox";
import useRegisterFormContext from 'hooks/useRegisterFormContext';
import PhoneInputField from 'components/fields/PhoneInputField';
import { Link } from 'react-router-dom';

function RegisterMoreDetails() {


    const { data, handleChange } = useRegisterFormContext()

    // Define errors state with initial values
    const [errors, setErrors] = useState({
        email: '',
        phone: '',
    });

    // Validation function for phone number
    const validatePhoneNumber = (phoneNumber) => {
        const phoneRegex = /^[0-9]*$/;
        return phoneRegex.test(phoneNumber);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        return emailRegex.test(email);
    };

    const handleEmailBlur = () => {
        if (!validateEmail(data.email)) {
            setErrors({
                ...errors,
                email: 'Invalid email address',
            });
        } else {
            setErrors({
                ...errors,
                email: '', // Clear the error when it's valid
            });
        }
    };

    const handlePhoneBlur = () => {
        // console.log(data.phoneNumber)
        if (!validatePhoneNumber(data.phoneNumber)) {
            setErrors({
                ...errors,
                phone: 'Invalid Phone Number',
            });
        } else {
            setErrors({
                ...errors,
                phone: '', // Clear the error when it's valid
            });
        }
    };

    return (

        <div className="mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
            {/* Sign in section */}
            <div className="mt-[5vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                    Register
                </h4>
                <p className="mb-9 ml-1 text-base text-gray-600">
                    Enter your details to Register
                </p>
                {/* <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800">
                    <div className="rounded-full text-xl">
                        <FcGoogle />
                    </div>
                    <h5 className="text-sm font-medium text-navy-700 dark:text-white">
                        Sign In with Google
                    </h5>
                    </div>
                    <div className="mb-6 flex items-center  gap-3">
                    <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
                    <p className="text-base text-gray-600 dark:text-white"> or </p>
                    <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
                    </div> */}

                {/* Phone Number */}
                <PhoneInputField
                    variant="auth"
                    extra="mb-3"
                    label={"Phone Number*"}
                    placeholder="Phone Number"
                    value={data.phoneNumber}
                    valueCountry={data.valueCountry}
                    onChange={handleChange}
                    onBlur={handlePhoneBlur}
                    name="phoneNumber"
                    id="phone-number"
                    type="tel"
                />
                {errors.phone && <div className="text-red-500 text-sm mt-2">{errors.phone}</div>}

                {/* Email */}
                <InputField2
                    variant="auth"
                    extra="mb-3"
                    label="Email*"
                    placeholder="mail@simmmple.com"
                    value={data.email}
                    onChange={handleChange}
                    onBlur={handleEmailBlur}
                    name="email"
                    id="email"
                    type="text"
                />
                {errors.email && <div className="text-red-500 text-sm mt-2">{errors.email}</div>}


                {/*  */}

                {/* Password */}
                <InputField2
                    variant="auth"
                    extra="mb-3"
                    label="Password*"
                    placeholder="Min. 8 characters"
                    value={data.password}
                    onChange={handleChange}
                    name="password"
                    id="password"
                    type="password"
                />

                {/* Address Line 1 */}
                {/* <InputField2
                    variant="auth"
                    extra="mb-3"
                    label="Address Line 1*"
                    placeholder="Address Line 1"
                    value={data.company}
                    onChange={handleChange}
                    name="addressLine1"
                    id="address-line-1"
                    type="text"
                /> */}

                {/* Address Line 1 */}
                {/* <InputField2
                    variant="auth"
                    extra="mb-3"
                    label="Address Line 2*"
                    placeholder="Address Line 2"
                    value={data.company}
                    onChange={handleChange}
                    name="addressLine2"
                    id="address-line-2"
                    type="text"
                /> */}

                {/* Checkbox */}
                {/* <div className="mb-4 flex items-center justify-between px-2">
                    <div className="flex items-center">
                        <Checkbox />
                        <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                            Keep me logged In
                        </p>
                    </div>
                    <a
                        className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                        href=" "
                    >
                        Forgot Password?
                    </a>
                </div> */}
                {/* <button className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                    Register
                </button> */}
                <div className="mt-4">
                    <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
                        Already Have an Acccount?
                    </span>
                    <Link
                        to={"/auth/sign-in"}
                        className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                    >
                        Sign In
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default RegisterMoreDetails
