// import InputField from "components/fields/InputField";
// import { FcGoogle } from "react-icons/fc";
// import Checkbox from "components/checkbox";
import { useState } from "react";
import useRegisterFormContext from "hooks/useRegisterFormContext";
import RegisterFormInputs from "./Register/RegisterFormInputs";
import { useMutation } from "@apollo/client"
import { CREATE_USER_MUTATION } from "graphql/auth/mutations"
import ErrorAlert from "components/ErrorAlert";
import { useNavigate } from "react-router-dom";
export default function Register() {

  const navigate = useNavigate()

  const [createUser] = useMutation(CREATE_USER_MUTATION);
  // console.log("GraphQL Query:", CREATE_USER_MUTATION?.loc?.source?.body);

  const {
    // page,
    setPage,
    data,
    // title,
    canSubmit,
    disablePrev,
    disableNext,
    prevHide,
    nextHide,
    submitHide
  } = useRegisterFormContext()

  const [error, setError] = useState('');

  // console.log(disablePrev, disableNext);

  const handlePrev = () => setPage(prev => prev - 1)

  const handleNext = () => setPage(prev => prev + 1)
  const handleSubmit = e => {
    e.preventDefault()
    // console.log(data);
    createUser({
      variables: {
        createUserInput: {
          first_name: data.firstName,
          last_name: data.lastName,
          email: data.email,
          phone_number: data.phoneNumber,
          password: data.password,
          country: data.country,
        },
      },
    })
      .then((response) => {
        // console.log("response: ", response);
        navigate('/auth/sign-in')
        // Handle other actions upon successful response, if needed
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error Encountered: ", error);
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          const errorMessage = error.graphQLErrors[0]?.message;
          if (errorMessage) {
            setError(errorMessage);
          } else {
            // Handle other GraphQL errors
          }
        } else {
          // Handle non-GraphQL errors (e.g., network errors)
          setError(error.message);
        }
      });
  }

  const onClose = () => {
      setError('');
  }

  return (
    <form className="form flex-col" onSubmit={handleSubmit}>

      {/* Conditionally render the error component */}
      {error && <ErrorAlert message={error} onClose={onClose} />}

      {/* <header className="form-header">
        <h2>{title[page]}</h2>

        <div className="button-container">

          <button type="button" className={`button ${prevHide}`} onClick={handlePrev} disabled={disablePrev}>Prev</button>

          <button type="button" className={`button ${nextHide}`} onClick={handleNext} disabled={disableNext}>Next</button>

          <button type="submit" className={`button ${submitHide}`} disabled={!canSubmit}>Submit</button>
        </div>
      </header> */}
      <div className="flex flex-col-reverse lg:flex-row items-center justify-between w-full">
        <div className="m-8 w-full max-w-full flex-col items-center lg:max-w-[420px]">
          <RegisterFormInputs />
          <div className="button-container gap-4 w-full max-w-full flex items-center xl:max-w-[420px]">

            <button
              className={`
            linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 
            ${prevHide}
          `}
              type="button"
              onClick={handlePrev}
              disabled={disablePrev}
            >
              Prev
            </button>

            <button
              className={`
            linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200
            ${nextHide}
          `}
              onClick={handleNext}
              disabled={disableNext}
            >
              Next
            </button>

            <button
              className={`
                linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200
                ${submitHide}
              `}
              type="submit"
              disabled={!canSubmit}
            >
              Register
            </button>
            {/* <button type="button" className={`button ${prevHide}`} onClick={handlePrev} disabled={disablePrev}>Prev</button>

        <button type="button" className={`button ${nextHide}`} onClick={handleNext} disabled={disableNext}>Next</button>

        <button type="submit" className={`button ${submitHide}`} disabled={!canSubmit}>Submit</button> */}
          </div>
        </div>
        <div>
          <img src="/Images/login_page.png" />
        </div>
      </div>
    </form>
  );
}
