import React from "react";
import countryData from "../../constants/countrycode.json";

function PhoneInputField(props) {
  const {
    label,
    id,
    extra,
    type,
    placeholder,
    variant,
    state,
    disabled,
    value,
    name,
    onChange,
    onBlur,
    valueCountry
  } = props;

  return (
    <div className={`${extra}`}>
      <label
        htmlFor={id}
        className={`text-sm text-navy-700 dark:text-white ${variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"
          }`}
      >
        {label}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 flex items-center">
          <label htmlFor="country" className="sr-only">
            Country
          </label>
          <select
            id="country"
            name="country"
            autoComplete="country"
            className="bg-transparent h-full w-full items-center justify-center rounded-md border-0 bg-white/0 p-3 py-0 pl-3 pr-7 text-sm text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
            value={valueCountry}
            onChange={onChange}
            defaultValue={valueCountry ? valueCountry : 'US'}
          >
            <option value="US">US</option>
            <option value="CN">CN</option>
            <option value="DE">DE</option>
            <option value="JP">JP</option>
            <option value="IN">IN</option>
            <br/>
            {countryData.map((data, index) => (
              <option value={data.code} key={index}>
                {data.code}
              </option>
            ))}
            {/* <option>IN</option>
            <option>US</option>
            <option>CA</option>
            <option>EU</option> */}
          </select>
        </div>
        <input
          disabled={disabled}
          type={type}
          id={id}
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          // className="block w-full rounded-md border-0 py-1.5 pl-16 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 pl-24 
                            text-sm  
                            outline-none ${disabled === true
              ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
              : state === "error"
                ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
                : state === "success"
                  ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
                  : "border-gray-200 dark:!border-white/10 dark:text-white"
            }`}
        />
      </div>
    </div>
  );
}

export default PhoneInputField;
