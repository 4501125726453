import React from 'react';
import YouTube from 'react-youtube';

const VideoPlayer = () => {
    const videoId = 'E7wJTI-1dvQ'; // Replace with your video ID
    const opts = {
        height: '272',
        width: '448',
        playerVars: {
            autoplay: 0,
        },
    };

    return (
        <div className='flex w-[310px] sm:w-full  overflow-x-scroll mt-4 justify-center h-full lg:w-full'>

            <YouTube videoId={videoId} opts={opts} />
        </div>
    );
};

export default VideoPlayer;
