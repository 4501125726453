// queries.js

import { gql } from '@apollo/client';

// Sample GraphQL queries
export const GET_ADMIN_SALES_THIS_MONTH = gql`
  mutation getAdminSalesThisMonth{
    getAdminSalesThisMonth {
      amount
    }
  }
`;

// Sample GraphQL queries
export const GET_ADMIN_SALES_LAST_THREE_MONTHS = gql`
  mutation getAdminSalesLastThreeMonths {
    getAdminSalesLastThreeMonths {
        amount
    }
    }
`;