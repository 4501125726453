// queries.js

import { gql } from '@apollo/client';

// Sample GraphQL queries
// export const GET_USER_BY_ID = gql`
//   query GetUserById($id: Float!){
//     getUserById(id:$id){
//       email
//       first_name
//       last_name
//     }
//   }
// `;

export const GET_CITY_WISE_DATA = gql`
  mutation FetchCityWiseData($productHandle:String!){
    fetchCityWiseData(productHandle:$productHandle){
      weeklyData {
        city
        metricValue
      }
      monthlyData {
        city
        metricValue
      }
    }
  }
`;

export const GET_COUNTRY_WISE_DATA = gql`
  mutation FetchCountryWiseData($productHandle:String!){
    fetchCountryWiseData(productHandle:$productHandle){
      weeklyData {
        country
        metricValue
      }
      monthlyData {
        country
        metricValue
      }
    }
  }
`;