// import CardMenu from "components/card/CardMenu";
// import { DiApple } from "react-icons/di";
// import { DiAndroid } from "react-icons/di";
// import { DiWindows } from "react-icons/di";
// import { FiSearch } from "react-icons/fi";
// import { BiEditAlt } from "react-icons/bi";
import { FaTrash } from "react-icons/fa";

import React, { useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
// import Progress from "components/progress";
import useAddProductFormContext from "hooks/useAddProductFormContext";
import Video from "./Video/video";

const AddDomainTable = (props) => {

  const { data, handleChange, setData, validateDomain } = useAddProductFormContext();
  // const [selectAll, setSelectAll] = useState(false);

  // Function to handle the delete action
  const handleDelete = (domainToDelete) => {
    console.log(domainToDelete)
    setData((prevData) => ({
      ...prevData,
      domainList: prevData.domainList.filter((domain) => domain.word !== domainToDelete),
    }));
  };


  // useEffect(() => {
  //   if (!loading && !error) {
  //     console.log(products); // You can use the products data here in your useEffect
  //     // Perform any additional logic with the products data
  //   }
  // }, [loading, error, products]);


  // const { columnsData, tableData } = props;
  // console.log(data.domainList);
  // const columns = useMemo(() => columnsData, [columnsData]);

  // const data = useMemo(() => tableData, [tableData]);
  const columns = useMemo(() => [
    // {
    //   Header: (
    //     <input
    //       type="checkbox"
    //       checked={selectAll}
    //       onChange={() => setSelectAll(!selectAll)}
    //     />
    //   ),
    //   accessor: "checkbox", // Virtual accessor for the checkbox column
    //   Cell: ({ row }) => {
    //     console.log(row); return (
    //       <input
    //         name="domainCheckbox"
    //         type="checkbox"
    //         checked={row.original.checked}
    //         onChange={handleChange}
    //       />
    //     )
    //   },
    //   id: "selectAll", // Unique ID for the select-all column
    //   disableSortBy: true, // Disable sorting for this column
    //   minWidth: 40,
    // },
    {
      Header: "Domain",
      accessor: "word",
      Cell: ({ row }) => {
        const [isEditing, setIsEditing] = useState(false);
        const [editedDomain, setEditedDomain] = useState(row.original.word);

        const handleEditClick = () => {
          setIsEditing(true);
        };

        const handleKeyDown = (e) => {
          if (e.key === "Enter") {
            // Enter key pressed, save the edited domain
            if (validateDomain(editedDomain)) {
              handleChange(
                {
                  target: {
                    name: "domainList",
                    value: data.domainList.map((domain) =>
                      domain.word === row.original.word
                        ? { ...domain, word: editedDomain, value: 'valid' }
                        : domain
                    ),
                  },
                }
              );
            } else {
              // Invalid domain, handle it accordingly
              alert("Invalid or Unsupported TLD");
            }
            setIsEditing(false);
          } else if (e.key === "Escape") {
            // Esc key pressed, cancel editing
            setIsEditing(false);
          }
        };

        // const handleSaveClick = () => {
        //   // Validate the editedDomain and update the context if valid
        //   if (validateDomain(editedDomain)) {
        //     // Update the context with the edited domain
        //     handleChange(
        //       {
        //         target: {
        //           name: "domainList",
        //           value: data.domainList.map((domain) =>
        //             domain.word === row.original.word
        //               ? { ...domain, word: editedDomain, value: "valid" }
        //               : domain
        //           ),
        //         },
        //       },
        //       "edit"
        //     );
        //     setIsEditing(false);
        //   } else {
        //     // Display an error or handle invalid input as needed
        //     alert("Invalid domain");
        //   }
        // };

        return (
          <div>
            {isEditing ? (
              <div className="flex items-center">
                <input
                  type="text"
                  value={editedDomain}
                  onChange={(e) => setEditedDomain(e.target.value)}
                  onKeyDown={handleKeyDown}
                  className="border border-gray-300 rounded-md px-2 py-1"
                  required
                />
                {/* <input
                  type="text"
                  value={editedDomain}
                  onChange={(e) => setEditedDomain(e.target.value)}
                  className="border border-gray-300 rounded-md px-2 py-1"
                />
                <button onClick={handleSaveClick} className="ml-2">
                  Save
                </button> */}
              </div>
            ) : (
              <div onClick={handleEditClick} className="cursor-pointer">
                {row.original.word}
              </div>
            )}
          </div>
        );
      },
    },
    {
      Header: "Buy Now Price",
      accessor: "buy-now-price",
    },
    {
      Header: "Floor Price",
      accessor: "floor-price",
    },
    {
      Header: "Minimum Offer",
      accessor: "minimum-offer",
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }) => {
        return (
          <span
            onClick={() => handleDelete(row.original.word)}
            className="text-red-500 cursor-pointer hover:text-red-600"
          >
            <FaTrash className="w-5 h-5" /> {/* Adjust the size as needed */}
          </span>
        );
      },
    },
  ], []);
  const tableInstance = useTable(
    {
      columns,
      data: data.domainList,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    // initialState,
  } = tableInstance;

  const handlePriceChange = (columnID, rowID, e) => {
    // console.log(columnID, rowID);
    const updatedData = [...data.domainList];
    console.log(data)

    // Find the row in the domainList array based on rowID
    const rowIndex = rowID; // You can use any suitable logic to find the index
    const rowData = updatedData[rowIndex];

    // Update the corresponding key in the rowData based on columnID
    switch (columnID) {
      case 'minimum-offer':
        rowData.minimumOffer = parseFloat(e.target.value);
        break;

      case 'floor-price':
        rowData.floorPrice = parseFloat(e.target.value);
        break;

      case 'buy-now-price':
        rowData.buyNowPrice = parseFloat(e.target.value);
        break;

      default:
        break;
    }

    // Update the data in the context
    setData((prevData) => ({
      ...prevData,
      domainList: updatedData,
    }));
  };

  const handleBlur = (e, columnID, rowID) => {
    const inputValue = parseFloat(e.target.value);
    const rowIndex = rowID;
    const updatedData = [...data.domainList];
    const rowData = updatedData[rowIndex];

    if (columnID === 'minimum-offer') {
      if (inputValue >= rowData['floorPrice'] || inputValue >= rowData['buyNowPrice']) {
        alert("Minimum Offer should be less than Floor Price and Buy Now Price");
        // Clear the input value
        e.target.value = '';
        // Reset the value in the data
        rowData['minimumOffer'] = ''; // Reset to an empty string
      } else {
        // Update the value in the data
        rowData['minimumOffer'] = inputValue;
      }
    } else if (columnID === 'floor-price') {
      if (inputValue >= rowData['buyNowPrice']) {
        alert("Floor Price should be less than Buy Now Price");
        // Clear the input value
        e.target.value = '';
        // Reset the value in the data
        rowData['floorPrice'] = ''; // Reset to an empty string
      } else {
        // Update the value in the data
        rowData['floorPrice'] = inputValue;
      }
    }

    // Update the data in the context
    setData((prevData) => ({
      ...prevData,
      domainList: updatedData,
    }));
  };

  // /////////////////////////////////////////////////////////
  return (
    <div>
      {/* Add search input here if needed */}
      <table
        className="w-full"
        variant="simple"
        color="gray-500"
        mb="24px"
        {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0" {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="divide-y divide-gray-200" {...getTableBodyProps()}>
          {page.map((row) => {
            // console.log("row: ", row);
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  // console.log(cell);
                  return (
                    <td className="pt-[14px] pb-[16px] sm:text-[14px]" {...cell.getCellProps()}>
                      {(cell.column.Header !== "Domain" && cell.column.Header !== 'Actions') ? <div className="relative mt-2 rounded-md shadow-sm w-24">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <span className="text-gray-500 sm:text-sm">$</span>
                        </div>
                        <input
                          name={`input-${cell.column.id}-${row.id}`}
                          id={`input-${cell.column.id}-${row.id}`}
                          className="block w-full rounded-md border-0 py-1.5 pl-5 pr-5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="0.00"
                          // value={cell.column.id === 'minimum-offer' ? 20 : null}
                          type="number"
                          min="1"
                          step="any"
                          // value={cell.getCellProps}
                          onChange={(e) => handlePriceChange(cell.column.id, row.id, e)}
                          onBlur={(e) => handleBlur(e, cell.column.id, row.id)}
                          aria-describedby="price-currency"
                        />
                      </div> : ''}

                      {cell.render("Cell")}
                      {cell.column.id === 'word' && data.domainList[row.index].value === 'invalid' && (
                        <div className="text-red-500 text-xs mt-1">Invalid or Unsupported TLD</div>
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {page.length === 0 && (
        <tr className="flex h-32  w-full items-center justify-center font-semibold text-red-600">
          No data found
        </tr>
      )}
      {/* <Video/> */}
    </div >
  );
};

export default AddDomainTable;
