import React, { useEffect, useState } from 'react'
// import LastWeekVisits from './components/analytics/LastWeekVisits';
import { useMutation, useQuery } from '@apollo/client';
import { GET_PRODUCTS } from 'graphql/products/queries';
import { useParams } from 'react-router-dom';
import LastWeekCityWise from './components/analytics/LastWeekCityWise';
import LastMonthCityWise from './components/analytics/LastMonthCityWise';
import { GET_CITY_WISE_DATA } from 'graphql/analytics/mutations';
import LastWeekCountryWise from './components/analytics/LastWeekCountryWise';
import LastMonthCountryWise from './components/analytics/LastMonthCountryWise';
import { GET_COUNTRY_WISE_DATA } from 'graphql/analytics/mutations';

export default function DomainAnalysis() {

    const params = useParams();
    console.log(params)

    // Query for products
    const { loading: loadingProducts, error: errorProducts, data: productData } = useQuery(GET_PRODUCTS);


    // Define initial state for monthly and weekly data
    const [dataCityWiseMonthly, setDataCityWiseMonthly] = useState([]);
    const [dataCityWiseWeekly, setDataCityWiseWeekly] = useState([]);

    
    const [dataCountryWiseMonthly, setDataCountryWiseMonthly] = useState([]);
    const [dataCountryWiseWeekly, setDataCountryWiseWeekly] = useState([]);

    // Mutation for city-wise data
    const [fetchCityWiseData, { loading: loadingCityWiseData, error: errorCityWiseData }] = useMutation(GET_CITY_WISE_DATA);
    const [fetchCountryWiseData, { loading: loadingCountryWiseData, error: errorCountryWiseData  }] = useMutation(GET_COUNTRY_WISE_DATA);
    // console.log(GET_COUNTRY_WISE_DATA?.loc?.source?.body)
    // useEffect(() => {
    //     const fetchDataCityWise = async () => {
    //         try {
    //             const { dataCityWiseData } = await fetchCityWiseData();
    //             if (dataCityWiseData) {
    //                 setDataCityWiseMonthly(dataCityWiseData.fetchCityWiseData.monthlyData);
    //                 setDataCityWiseWeekly(dataCityWiseData.fetchCityWiseData.weeklyData);
    //             }
    //         } catch (err) {
    //             console.error('Error fetching city-wise data:', err);
    //         }
    //     };

    //     fetchDataCityWise();
    // }, []);
    useEffect(() => {
        fetchCityWiseData({
            variables: {
                "productHandle": `/products/${params.handle}`
            }
        }).then((result) => {
            if (result.data && result.data.fetchCityWiseData) {
                const dataMonthly = result.data.fetchCityWiseData.monthlyData;
                const dataWeekly = result.data.fetchCityWiseData.weeklyData;
                setDataCityWiseMonthly(dataMonthly)
                setDataCityWiseWeekly(dataWeekly);
            }
        }).catch((error) => {
            console.error("Error Fetching Data: ", error.message)
        });

        
        fetchCountryWiseData({
            variables: {
                "productHandle": `/products/${params.handle}`
            }
        }).then((result) => {
            if (result.data && result.data.fetchCountryWiseData) {
                const dataMonthly = result.data.fetchCountryWiseData.monthlyData;
                const dataWeekly = result.data.fetchCountryWiseData.weeklyData;
                setDataCountryWiseMonthly(dataMonthly)
                setDataCountryWiseWeekly(dataWeekly);
            }
        }).catch((error) => {
            console.error("Error Fetching Data: ", error)
        });
    }, []);

    if (loadingProducts || loadingCityWiseData || loadingCountryWiseData) return <p>Loading...</p>;
    if (errorProducts || errorCityWiseData || errorCountryWiseData) {
        // Handle error cases here
        return <p>Error: {
            errorProducts ?
                errorProducts.message :
                errorCityWiseData ? errorCityWiseData.message :
                errorCountryWiseData ? errorCountryWiseData.message : null
        }</p>;
    }

    // console.log(productData)
    // console.log(dataCityWiseWeekly)
    // console.log(dataCityWiseMonthly)

    // if (loadingCityWiseData) return <p>Loading...</p>;
    // if (errorCityWiseData) return <p>Error: {errorCityWiseData.message}</p>;
    // const shopifyData = [
    //     {
    //         "node": {
    //             "title": "CampusJourney",
    //             "handle": "campusjourney"
    //         }
    //     },
    //     {
    //         "node": {
    //             "title": "Bindaas 3",
    //             "handle": "bindaas-3"
    //         }
    //     },
    //     {
    //         "node": {
    //             "title": "CRMPRIMER",
    //             "handle": "crmprimer"
    //         }
    //     },
    //     {
    //         "node": {
    //             "title": "customer360x.com",
    //             "handle": "customer360x-com"
    //         }
    //     },
    // ];

    const analyticsData = [
        {
            "dimension": {
                "value": "/products/campusjourney",
                "oneValue": "value"
            },
            "metricValue": {
                "value": "324",
                "oneValue": "value"
            }
        },
        {
            "dimension": {
                "value": "/products/bindaas-3",
                "oneValue": "value"
            },
            "metricValue": {
                "value": "302",
                "oneValue": "value"
            }
        },
        {
            "dimension": {
                "value": "/products/crmprimer",
                "oneValue": "value"
            },
            "metricValue": {
                "value": "299",
                "oneValue": "value"
            }
        },
    ];

    // Extract dimension values from Shopify handles
    console.log(productData)
    // const shopifyHandles = productData?.products?.map((edge) => {
    //     return '/products/' + edge.handle;
    // });

    // Create a mapping between Shopify handles and Google Analytics dimension values
    const mapping = {};
    analyticsData.forEach((item) => {
        mapping[item.dimension.value] = item.metricValue.value;
    });

    // // Collect metric values from Google Analytics based on Shopify handles
    // const metricValues = shopifyHandles.map((handle) => {
    //     return mapping[handle] || 0; // Default to 0 if no match found
    // });

    // // console.log(metricValues); // Metric values for Shopify handles
    // const barChartDataWeeklyRevenue = [
    //     {
    //         name: "Brandcart.com",
    //         data: ["metricValues"],
    //         color: '#4318FF'
    //     }
    // ]

    // const barChartDataWeeklyCityWise = [
    //     {
    //         name: '',
    //         data: '',
    //         color: '#4318FF',
    //     }
    // ]

    const citiesMonthWise = dataCityWiseMonthly.map((dataItem) => dataItem.city);
    const citiesWeekWise = dataCityWiseWeekly.map((dataItem) => dataItem.city);
    // console.log(citiesCityWise)
    const metricValuesMonthlyCityWise = dataCityWiseMonthly.map((dataItem) => dataItem.metricValue);
    const barChartDataMonthlyCityWise = [
        {
            name: '',
            data: metricValuesMonthlyCityWise,
            color: '#4318FF',
        }
    ]
    const metricValuesWeeklyCityWise = dataCityWiseWeekly.map((dataItem) => dataItem.metricValue);
    const barChartDataWeeklyCityWise = [
        {
            name: '',
            data: metricValuesWeeklyCityWise,
            color: '#4318FF',
        }
    ]

    

    const countriesMonthWise = dataCountryWiseMonthly.map((dataItem) => dataItem.country);
    const countriesWeekWise = dataCountryWiseWeekly.map((dataItem) => dataItem.country);
    // console.log(countriesCityWise)
    const metricValuesMonthlyCountryWise = dataCountryWiseMonthly.map((dataItem) => dataItem.metricValue);
    const barChartDataMonthlyCountryWise = [
        {
            name: '',
            data: metricValuesMonthlyCountryWise,
            color: '#4318FF',
        }
    ]
    const metricValuesWeeklyCountryWise = dataCountryWiseWeekly.map((dataItem) => dataItem.metricValue);
    const barChartDataWeeklyCountryWise = [
        {
            name: '',
            data: metricValuesWeeklyCountryWise,
            color: '#4318FF',
        }
    ]

    return (
        <div>
            <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
                {/* <TotalSpent /> */}
                <LastWeekCityWise barChartDataWeeklyCityWise={barChartDataWeeklyCityWise} citiesWeekWise={citiesWeekWise} />
                <LastMonthCityWise barChartDataMonthlyCityWise={barChartDataMonthlyCityWise} citiesMonthWise={citiesMonthWise} />
                
                <LastWeekCountryWise barChartDataWeeklyCountryWise={barChartDataWeeklyCountryWise} countriesWeekWise={countriesWeekWise} />
                <LastMonthCountryWise barChartDataMonthlyCountryWise={barChartDataMonthlyCountryWise} countriesMonthWise={countriesMonthWise} />
                {/* <LastWeekVisits barChartDataWeeklyCityWise={barChartDataWeeklyCityWise} citiesWeekWise={citiesWeekWise}/> */}
            </div>
        </div >
    )
}
