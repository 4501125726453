import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import routes from "routes.js";
// import Sidebar from "components/sidebar/sidebar";

export default function Portfolio(props) {
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);
  React.useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  // const getRoutes = (routes) => {
  //   console.log("hello")
  //   return routes.map((prop, key) => {
  //     if (prop.layout === "/portfolio") {
  //       console.log(`${prop.path}`)
  //       console.log(prop.component)
  //       return (
  //         <Route path={`/${prop.path}`} element={prop.component} key={key} />
  //       );
  //     } else {
  //       return null;
  //     }
  //   });
  // };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/portfolio") {
        if (prop.children) {
          const childRoutes = prop.children.map((child, keyChild) => {
            return (
            <Route path={`${child.layout}/${child.path}`} element={child.component} key={keyChild} />
          )});
          // console.log(childRoutes);
          return [
            <Route path={`/${prop.path}`} element={prop.component} key={key} />,
            ...childRoutes, // Spread the child routes array
          ];
        } else {
          // console.log(<Route path={`${prop.layout}${prop.path}`} element={prop.component} key={key} />)
          return <Route path={`${prop.layout}${prop.path}`} element={prop.component} key={key} />;
        }
      } else {
        return null;
      }
    });
  };
  
  document.documentElement.dir = "ltr";
  // console.log("lakdsjf");
  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      {/* <Sidebar /> */}
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        {/* Main Content */}
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
        >
          {/* Routes */}
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              logoText={"Horizon UI Tailwind React"}
              brandText={currentRoute}
              secondary={getActiveNavbar(routes)}
              {...rest}
            />
            <div className="pt-5 mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
              <Routes>
                {getRoutes(routes)}

                {/* <Route
                  path="/"
                  element={<Navigate to="/portfolio/" replace />}
                /> */}
              </Routes>
            </div>
            <div className="p-3">
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
