// import CheckTable from "./components/CheckTable";

// import {
//   columnsDataDevelopment,
//   columnsDataCheck,
//   columnsDataColumns,
//   columnsDataComplex,
//   DomainListingPortfolio,
// } from "./variables/columnsData";
// import DomainListingTable from "./components/DomainListingTable";
// import ColumnsTable from "./components/ColumnsTable";
// import ComplexTable from "./components/ComplexTable";
// import { useQuery } from '@apollo/client';
// import { GET_PRODUCTS } from 'graphql/products/queries';
// import MyProducts from "./components/MyProducts";
import AddProductComponent from "./components/AddProductComponent";
import { AddProductFormProvider } from "context/AddProductFormContext";

const AddProduct = () => {
  return (
    <div>
      <div className="mt-5 grid h-full">
        {/* <DomainListingTable
          columnsData={DomainListingPortfolio}
          tableData={data.products}
        /> */}
        <AddProductFormProvider>
          <AddProductComponent />
        </AddProductFormProvider>
        {/* <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} /> */}
        {/* <MyProducts
          columnsData={DomainListingPortfolio}
          tableData={data.products}
        /> */}
      </div>

      {/* <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-2">
        <ColumnsTable
          columnsData={columnsDataColumns}
          tableData={tableDataColumns}
        />

        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
      </div> */}
    </div>
  );
};

export default AddProduct;
