// VerificationSuccess.jsx
import React from 'react';
import { Link } from 'react-router-dom';

const EmailVerificationSuccess = () => {
  return (
    <div className='flex items-center justify-center h-screen w-full'>
      <div className="w-96 bg-green-200 p-4 rounded-lg">
        <div className="text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-16 w-16 text-green-500 mx-auto"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 13l4 4L19 7"
            />
          </svg>
          <h2 className="text-3xl font-bold text-green-700">Verification Successful</h2>
          <p className="text-green-600">Your email has been successfully verified.</p>
          <Link className='text-[#01afef]' to={'/'}>Go to Home</Link>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationSuccess;
