// queries.js

import { gql } from '@apollo/client';

// Sample GraphQL queries
export const GET_PRODUCTS = gql`
  query GetProducts {
    products {
      id
      title
      price
      status
      handle
    }
  }
`;

// Sample GraphQL queries
export const GET_LIVE_PRODUCTS_BY_VENDOR = gql`
  query GetLiveProductsByVendor($vendor:String!){
    getLiveProductsByVendor(vendor:$vendor) {
      id
      title
      price
      status
      handle
    }
  }
`;

export const ADD_PRODUCT_MUTATION = gql`
  mutation CreateProduct($createProductInput: CreateProductInput!) {
    createProduct(createProductInput: $createProductInput) {
      title
    }
  }
`;

export const GET_PRODUCT_BY_ID = gql`
  query GetProductById($productId: ID!) {
    product(id: $productId) {
      id
      title
      price
      description
    }
  }
`;

export const sampleProduct = {
  id: '1',
  title: 'Sample Product',
  price: 29.99,
  description: 'This is a sample product description.',
};

export const GET_NON_APPROVED_DOMAINS = gql`
query FindNonApprovedDomains {
  findNonApprovedDomains{
    id
    word
    value
    checked
    buyNowPrice
    floorPrice
    minimumOffer
    status
    createdAt
    createdBy {
      merchant_id
    }
  }
}
`

export const GET_APPROVED_DOMAINS = gql`
  query FindApprovedDomains {
    findApprovedDomains{
      id
      word
      value
      checked
      isVerified
      buyNowPrice
      floorPrice
      minimumOffer
      status
    }
  }
`

export const GET_REJECTED_DOMAINS = gql`
  query FindRejectedDomains {
    findRejectedDomains{
      id
      word
      value
      checked
      isVerified
      buyNowPrice
      floorPrice
      minimumOffer
      status
    }
  }
`