// users mutations.js

import { gql } from '@apollo/client';

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
        phone_number
        email
        last_name
        first_name
    }
  }
`;

export const GENERATE_OTP_MUTATION = gql`
  mutation generateOTP($email:String!){
    generateOTP(email:$email)
  }
`;

export const VERIFY_OTP_MUTATION = gql`
  mutation verifyOTP($email:String!,$otp:String!) {
    verifyOTP(email:$email,otp:$otp)
  }
`;

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation updatePassword($email:String!,$newPassword:String!) {
    updatePassword(email:$email,newPassword:$newPassword)
  }  
`