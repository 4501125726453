import React, { useState } from 'react';
import InputField2 from "components/fields/InputField2";
// import { FcGoogle } from "react-icons/fc";
// import Checkbox from "components/checkbox";
import useForgotPasswordFormContext from 'hooks/useForgotPasswordFormContext';
import PhoneInputField from 'components/fields/PhoneInputField';
import { Link } from 'react-router-dom';

function ForgotPasswordSetPassword() {


    const { data, handleChange } = useForgotPasswordFormContext()

    // Define errors state with initial values
    const [errors, setErrors] = useState({
        otp: '',
    });

    // Validation function for phone number
    const validateOTP = (otp) => {
        const phoneRegex = /^[0-9]*$/;
        return phoneRegex.test(otp);
    };

    const handleOTPBlur = () => {
        if (!validateOTP(data.otp)) {
            setErrors({
                ...errors,
                otp: 'Invalid OTP',
            });
        } else {
            setErrors({
                ...errors,
                otp: '', // Clear the error when it's valid
            });
        }
    };

    return (

        <div className="mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:items-center lg:justify-start">
            {/* Reset Password section */}
            <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                    Reset Password
                </h4>
                <p className="mb-9 ml-1 text-base text-gray-600">
                    Enter your details to Reset Password
                </p>
                 {/* Password */}
                 <InputField2
                    variant="auth"
                    extra="mb-3"
                    label="Password*"
                    placeholder="Min. 8 characters"
                    value={data.password}
                    onChange={handleChange}
                    name="password"
                    id="password"
                    type="password"
                />
                {errors.password && <div className="text-red-500 text-sm mt-2">{errors.password}</div>}

                 {/* Confirm Password */}
                 <InputField2
                    variant="auth"
                    extra="mb-3"
                    label="Confirm Password*"
                    placeholder="Min. 8 characters"
                    value={data.confirmPassword}
                    onChange={handleChange}
                    name="confirmPassword"
                    id="confirmPassword"
                    type="password"
                />
                {errors.confirmPassword && <div className="text-red-500 text-sm mt-2">{errors.confirmPassword}</div>}
            </div>
        </div>
    )
}

export default ForgotPasswordSetPassword
