import { createContext, useState } from "react"

const AddProductFormContext = createContext({})

export const AddProductFormProvider = ({ children }) => {

    const tagSuggestions = [
        "5-Letter",
        "Advertising",
        "Agriculture",
        "Analytics",
        "Apparel",
        "Apps",
        "Architecture",
        "Aroma",
        "Art",
        "Artificial Intelligence",
        "Automotive",
        "Ayurveda",
        "Banks",
        "Bars",
        "Beaut",
        "Beauty",
        "Biotech",
        "Blockchain",
        "Books",
        "Bots",
        "BPOs",
        "Building",
        "Call-Centers",
        "Candles",
        "canvas",
        "Car",
        "Catchy",
        "Charities",
        "Charity",
        "Chat",
        "Cinema",
        "Cleaning",
        "Clever",
        "Clinics",
        "Clothing",
        "Cloud",
        "Coaching",
        "Community",
        "Companies",
        "Computers",
        "Conferences",
        "Construction",
        "Consulting",
        "Consumers",
        "Cosmetics",
        "Crypto",
        "Curated",
        "Customer-Service",
        "Dating",
        "Dental",
        "Design",
        "Diamonds",
        "Dictionary-Word",
        "Digital",
        "Direct-Selling",
        "Distribution",
        "Drink",
        "E-Commerce",
        "Economy",
        "Education",
        "Electronic",
        "Electronics",
        "Energy",
        "Entertainment",
        "Environment",
        "Events",
        "Exterior",
        "Farming",
        "Fashion",
        "Finance",
        "Financial",
        "Fintech",
        "Fitness",
        "Food",
        "Food-Delivery",
        "Foundation",
        "Fragrances",
        "Furniture-Based",
        "Gadgets",
        "Games",
        "Garden",
        "Green",
        "Green-Energy",
        "Gym",
        "Hair",
        "Hardware",
        "Health",
        "High-tech",
        "Home",
        "Household",
        "HR",
        "Incentives",
        "Incubators and Innovation",
        "Insights",
        "Inspection",
        "Interior",
        "Internet",
        "Jewelry",
        "Learning",
        "Legal",
        "Logistics",
        "Makeup",
        "Manufacturing",
        "Marketing",
        "Medical",
        "Meetings",
        "Metaverse",
        "MLM",
        "Mobile",
        "MOONSHOTTER",
        "Motivational",
        "Music",
        "NFT",
        "NGOs",
        "Non-profit",
        "Online-Ticketing",
        "Organic",
        "Outdoor-Companies",
        "Painting",
        "Pet Supplies",
        "Pets",
        "Pharma",
        "Political",
        "Premium",
        "Professional",
        "Property",
        "Real Estate",
        "Reality",
        "Recreational",
        "Recruitment",
        "Relationships",
        "Religious",
        "Research",
        "Restaurant",
        "Restaurants",
        "Retail",
        "Safety",
        "Scents",
        "Security",
        "Self-help",
        "Services",
        "Shoppertainment",
        "Snacks",
        "Social Networking",
        "Software",
        "Solar",
        "Spa",
        "Spiritual",
        "Sports",
        "Startups",
        "Style",
        "Stylists",
        "Supplements",
        "Technology",
        "Telephony",
        "Toys",
        "Transportation",
        "Travel",
        "Tutoring",
        "tv extension",
        "Virtual",
        "Vitamins",
        "Wellness",
    ];

    const title = {
        0: 'Add Domain',
        1: 'Add Form Table',
        // 2: 'Domain Ownership Check',
    }

    const [page, setPage] = useState(0)
    // const [verificationFinalStatusData, setVerificationFinalStatusData] = useState([]);

    const [data, setData] = useState({
        // productTitle: '',
        // price: 0.0,
        // description: '',
        // collectionsToJoin: "gid://shopify/Collection/448911442215",
        // productTags: [],
        // productDomain: '',
        // media: [],
        rawDomainsList: '',
        domainList: [],
        // verificationRecord: '',
        // loaderOpen: '',
    })


    // useEffect(() => {
    //     if (data.sameAsBilling) {
    //         setData(prevData => ({
    //             ...prevData,
    //             shipFirstName: prevData.billFirstName,
    //             shipLastName: prevData.billLastName,
    //             shipAddress1: prevData.billAddress1,
    //             shipAddress2: prevData.billAddress2,
    //             shipCity: prevData.billCity,
    //             shipState: prevData.billState,
    //             shipZipCode: prevData.billZipCode
    //         }))
    //     } else {
    //         setData(prevData => ({
    //             ...prevData,
    //             shipFirstName: "",
    //             shipLastName: "",
    //             shipAddress1: "",
    //             shipAddress2: "",
    //             shipCity: "",
    //             shipState: "",
    //             shipZipCode: ""
    //         }))
    //     }
    // }, [data.sameAsBilling])

    // const handleProductDescriptionChange = e => {
    //     setData(prevData => ({
    //         ...prevData,
    //         description: 
    //     }))
    // }
    // const [description, setDescription] = useState('');// Relevant
    const validateDomain = (domain) => {
        // List of valid domain extensions
        const validExtensions = ['.com', '.co', '.xyz', '.net', '.org', '.ai', '.io', '.biz', '.me', '.cx', '.digital', '.club', '.in', '.cloud', '.info', '.ly', '.art', '.us', '.shop', '.sh', '.app', '.homes', '.business', '.mba', '.do', '.ws', '.lat', '.co.in', '.es', '.tv']; // Add more extensions as needed

        // Check if the domain ends with any of the valid extensions
        const isValid = validExtensions.some((extension) =>
            domain.endsWith(extension)
        );

        // Check for additional validation criteria if needed
        const isLengthValid = domain.length > 4; // Check for minimum length

        // Return true if the domain is valid based on both extension and length criteria
        return isValid && isLengthValid;
    };

    // Relevant
    const extractWords = (text) => {
        const words = text.trim().split(/\s+|,|\n/);
        const wordData = [];

        words.forEach((word) => {
            const cleanedWord = word.trim();
            const isValidDomain = validateDomain(cleanedWord);
            const value = isValidDomain ? 'valid' : 'invalid';

            wordData.push({
                word: cleanedWord,
                value: value,
                checked: false,
                buyNowPrice: 0,
                floorPrice: 0,
                minimumOffer: 0,
            });
        });

        return wordData;
    };


    const handleChange = e => {
        const { type, name } = e.target;

        const value = type === "checkbox"
            ? e.target.checked
            : (name && name === "price") ? parseFloat(e.target.value) : e.target.value;
        // value = (name && name === "domainList") ? extractWords(e.target.value) : value;
        if (name && name === 'rawDomainsList') {
            setData(prevData => ({
                ...prevData,
                ['domainList']: extractWords(e.target.value)
            }))
        }

        // console.log(type, name, value)

        setData(prevData => ({
            ...prevData,
            [name]: value
        }))
        // console.log(data);
    }

    const {
        // billAddress2,
        // sameAsBilling,
        // shipAddress2,
        // optInNews,
        ...requiredInputs } = data

    // console.log(data);

    const canSubmit = [...Object.values(requiredInputs)].every(Boolean) && page === Object.keys(title).length - 1

    const canNextPage1 = Object.keys(data)
        .filter(key => key.startsWith('bill') && key !== 'billAddress2')
        .map(key => data[key])
        .every(Boolean)

    const canNextPage2 = Object.keys(data)
        .filter(key => key.startsWith('ship') && key !== 'shipAddress2')
        .map(key => data[key])
        .every(Boolean)

    const disablePrev = page === 0

    const disableNext =
        (page === Object.keys(title).length - 1)
        || (page === 0 && !canNextPage1)
        || (page === 1 && !canNextPage2)

    const prevHide = page === 0 && "remove-button"

    const nextHide = page === Object.keys(title).length - 1 && "remove-button"

    const submitHide = page !== Object.keys(title).length - 1 && "remove-button"

    return (
        <AddProductFormContext.Provider value={{ title, page, setPage, data, setData, canSubmit, handleChange, disablePrev, disableNext, prevHide, nextHide, submitHide, tagSuggestions, validateDomain }}>
            {children}
        </AddProductFormContext.Provider>
    )
}

export default AddProductFormContext 