import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

// import RtlLayout from "layouts/rtl";
// import AdminLayout from "layouts/admin";
// import Portfolio from "layouts/portfolio";
// import AddDomain from "layouts/portfolio/add/index";
// import AuthLayout from "layouts/auth";
// import DomainOwnershipCheck from "layouts/domain-ownership-check/DomainOwnershipCheck";
// import Domain from "layouts/domain/Domain";
import AppRouter from "AppRouter";
import Loader from "components/Loader";
import jwtDecode from "jwt-decode";
import { useLazyQuery } from "@apollo/client";
import { GET_USER_BY_ID } from "graphql/auth/queries";
import { RESEND_VERIFICATION_EMAIL } from "graphql/auth/queries";
import Notification from "components/Notification/Notification";
import isEqual from "lodash/isEqual";

// Define the initial state and reducer for managing authentication state
const initialState = {
  token: localStorage.getItem("token") || "", // Retrieve token from storage
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("token", action.payload); // Store token in storage
      return { ...state, token: action.payload };
    case "LOGOUT":
      localStorage.removeItem("token"); // Remove token from storage
      return { ...state, token: "" };
    default:
      return state;
  }
};

const AuthContext = createContext();

export const AuthProvider = ({ children, onLogin, token, onLogout }) => {
  // console.log(onLogin)
  return (
    <AuthContext.Provider value={{ onLogin, token, onLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

const App = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [loaderOpen, setLoaderOpen] = useState(false);
  const [showNotification, setShowNotification] = useState({
    status: false,
    value: "",
  });
  const [userData, setUserData] = useState(null);

  const [executeQuery, { loading, data, refetch }] =
    useLazyQuery(GET_USER_BY_ID);
  const [
    executeQueryVerificationEmail,
    {
      loadingVerificationEmail,
      dataVerificationEmail,
      refetchVerificationEmail,
    },
  ] = useLazyQuery(RESEND_VERIFICATION_EMAIL);

  var currentPath = window.location.pathname;
  var pathArray = currentPath.split("/");

  const navigate = useNavigate();

  const isTokenExpired = (token) => {
    if (!token) {
      return true; // Token does not exist
    }
    const currentTime = Date.now(); // Current time in milliseconds
    const expirationTime = jwtDecode(token).exp * 1000; // Expiration time in milliseconds
    console.log(currentTime, expirationTime, token);
    return currentTime > expirationTime; // Check if current time is greater than expiration time
  };
  console.log(isTokenExpired);
  useEffect(() => {
    if (isTokenExpired(token)) {
      if (
        pathArray.includes("forgot-password") ||
        pathArray.includes("verify-token")
      ) {
        // setToken
      } else {
        handleLogout();
      }
    } else if (token) {
      setLoaderOpen(true);
      const user = jwtDecode(token);
      // if (user) {
      //   executeQuery({ variables: { id: user.sub } })
      //     .then((result) => {
      //       setUserData(result.data.getUserById);
      //     })
      // }
      setTimeout(() => {
        if (user.roles.includes("Vendor")) {
          navigate("/portfolio/my_approved_domains");
        } else {
          navigate("/portfolio");
        }
        setLoaderOpen(false);
      }, 3000);
    }
  }, []);

  useEffect(() => {
    if (token) {
      const user = jwtDecode(token);
      if (user) {
        executeQuery({ variables: { id: user.sub } }).then((result) => {
          setUserData(result.data.getUserById);
        });
      }
    }
  }, [token]);

  const handleLogin = (newToken) => {
    const expirationTime = newToken.access_token.exp; // Convert expiration time to milliseconds
    dispatch({
      type: "LOGIN",
      payload: newToken.access_token,
      expiration: expirationTime,
    });
    setToken(newToken.access_token);
    setLoaderOpen(true);
    setTimeout(() => {
      const user = jwtDecode(newToken.access_token);
      if (user.roles.includes("Vendor")) {
        navigate("/portfolio/my_approved_domains");
      } else {
        navigate("/portfolio");
      }
      setLoaderOpen(false);
    }, 3000);
  };

  const handleLogout = () => {
    console.log("handle logout called");
    dispatch({ type: "LOGOUT" });
    setToken("");
    setLoaderOpen(true);
    setUserData(null);
    setTimeout(() => {
      navigate("/auth");
      setLoaderOpen(false);
    }, 3000);
  };

  const resendVerificationEmail = async (email) => {
    const response = await executeQueryVerificationEmail({
      variables: {
        email: email,
      },
    });
    if (response.data.resendVerificationEmail == "Email Sent Successfully.") {
      setShowNotification({ status: true, value: "Email Sent Successfully." });
    }
  };

  return (
    <div>
      <Loader loaderOpen={loaderOpen} setLoaderOpen={setLoaderOpen} />
      <AuthProvider onLogin={handleLogin} onLogout={handleLogout} token={token}>
        {showNotification.status && (
          <Notification
            showNotification={showNotification}
            setShowNotification={setShowNotification}
          />
        )}
        <div>
          {userData && !userData.isEmailVerified && (
            <div className="fixed left-0 top-0 z-[100] w-full bg-red-500 p-4 text-xs text-white md:text-base">
              <p className="whitespace-wrap">
                Your email is not verified. Please check your inbox and verify
                your email.
                <br className="md:hidden" />
                <a
                  className="cursor-pointer underline"
                  onClick={() => resendVerificationEmail(userData.email)}
                >
                  {" "}
                  Resend Email
                </a>
              </p>
            </div>
          )}

          <div className={userData && !userData.isEmailVerified ? "pt-12" : ""}>
            <AppRouter />
          </div>
        </div>
      </AuthProvider>
    </div>
  );
};

export default App;
