// VerificationFailed.jsx
import React from 'react';

const EmailVerificationFailed = () => {
  return (<div className='flex items-center justify-center h-screen w-full'>
    <div className="w-96 bg-red-200 p-4 rounded-lg">
      <div className="text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="h-16 w-16 text-red-500 mx-auto"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
        <h2 className="text-3xl font-bold text-red-700">Verification Failed</h2>
        <p className="text-red-600">Sorry, the verification link is invalid or has expired.</p>
      </div>
    </div>
  </div>
  );
};

export default EmailVerificationFailed;
