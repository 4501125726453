

import React from "react";
import { useLocation } from "react-router-dom";
// import Navbar from "components/navbar";
// import Sidebar from "components/sidebar";
// import Footer from "components/footer/Footer";
import routes from "routes.js";
// import CheckDomainOwnership from "components/DomainOwnershipCheck/CheckDomainOwnership";
import useAddProductFormContext from "hooks/useAddProductFormContext";

export default function Admin(props) {

    const { data, handleChange } = useAddProductFormContext()

    // Check for Relevance
    // const { ...rest } = props;
    const location = useLocation();
    const [open, setOpen] = React.useState(true);
    const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");

    React.useEffect(() => {
        window.addEventListener("resize", () =>
            window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
        );
    }, []);
    React.useEffect(() => {
        getActiveRoute(routes);
    }, [location.pathname]);

    const getActiveRoute = (routes) => {
        let activeRoute = "Main Dashboard";
        for (let i = 0; i < routes.length; i++) {
            if (
                window.location.href.indexOf(
                    routes[i].layout + "/" + routes[i].path
                ) !== -1
            ) {
                setCurrentRoute(routes[i].name);
            }
        }
        return activeRoute;
    };
    // const getActiveNavbar = (routes) => {
    //     let activeNavbar = false;
    //     for (let i = 0; i < routes.length; i++) {
    //         if (
    //             window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
    //         ) {
    //             return routes[i].secondary;
    //         }
    //     }
    //     return activeNavbar;
    // };
    // ////////////////////////////////////////////////////////

    // const getRoutes = (routes) => {
    //     return routes.map((prop, key) => {
    //         if (prop.layout === "/admin") {
    //             return (
    //                 <Route path={`/${prop.path}`} element={prop.component} key={key} />
    //             );
    //         } else {
    //             return null;
    //         }
    //     });
    // };

    document.documentElement.dir = "ltr";
    // /////////////////////////////////////////////////////////
    // const [postContent, setPostContent] = useState('');

    // const handleFormSubmit = (event) => {
    //     event.preventDefault();
    //     const wordData = extractWords(postContent);
    //     console.log('Published post:', postContent);
    //     console.log('Word data:', wordData);
    // };


    // const handleTextareaChange = (event) => {
    //     setPostContent(event.target.value);
    // };

    // Relevant
    let wordCount = 0;
    if (data.rawDomainsList.length > 0) {
        wordCount = data.rawDomainsList.trim().split(/\s+/).length;
    }

    // /////////////////////////////////////////////////////////
    return (
        <div className="flex w-full">
            <div className="h-full w-full">
                <main
                    className={`mx-[12px] h-full flex-none transition-all md:pr-2`}
                >
                    <div className="mt-2">
                        <p className="">Type or paste a list of domains you own.</p>
                        <p className="">Domains can be separated by newlines (⏎) or spaces.</p>
                        <p className="mt-5">{wordCount} domain found</p>

                        {/* <form className=" " onSubmit={handleFormSubmit}> */}
                        <div className="w-full mb-4 border border-gray-600 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 ">
                            <div className="px-4 py-2 bg-white rounded-t-lg ">
                                <label for="comment" className="sr-only">Your comment</label>
                                <textarea
                                    id="raw-domain-list"
                                    name="rawDomainsList"
                                    value={data.rawDomainsList}
                                    onChange={handleChange}
                                    rows="4" className=" h-[40vh] w-full px-0 text-sm text-gray-900 bg-white dark:bg-gray-800 focus:ring-0 outline-none dark:text-white dark:placeholder-gray-400" placeholder="List your domains here..." required></textarea>
                            </div>
                        </div>
                        {/* </form> */}

                    </div>
                </main>
            </div>
        </div>
    );
}

