import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";

import App from "./App";
// import reportWebVitals from './reportWebVitals';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
// import { onError } from '@apollo/client/link/error';

// const errorLink = onError(({ graphqlErrors, networkError }) => {
//   console.log("networkError");
//   console.log(networkError)
//   console.log("grahpqlErrors");
//   console.log(graphqlErrors);
//   if (graphqlErrors) {
//     graphqlErrors.map(({ message, location, path }) => {
//       alert(`Graphql error ${message}`)
//     })
//   }
// })
// const link = from([
//   errorLink,
//   new HttpLink({ uri: "http://localhost:3001/graphql" })
// ])

// const client = new ApolloClient({
//   cache: new InMemoryCache(),
//   link: link
// })

const client = new ApolloClient({
  uri: 'https://api.seller.brandcart.com/graphql', // Your GraphQL API endpoint
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloProvider>
);
