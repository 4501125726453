import useRegisterFormContext from "hooks/useRegisterFormContext"
import RegisterPesonalDetails from "./RegisterPesonalDetails"
import RegisterMoreDetails from "./RegisterMoreDetails"
// import BrandCartLogo from './../../../assets/img/brandcart/brandcart_logo.webp'

const RegisterFormInputs = () => {

    const { page } = useRegisterFormContext()
    // console.log(page);
    const display = {
        0: <RegisterPesonalDetails />,
        1: <RegisterMoreDetails />,
    }

    const content = (
        <div>
            <div className="form-inputs flex-col">
                {display[page]}
            </div>
        </div>
    )


    return content
}
export default RegisterFormInputs