// import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFilters, usePagination, useSortBy, useTable } from "react-table";
import Loader from "components/Loader";
// import CardMenu from "components/card/CardMenu";
import Card from "components/card";

// import { gql } from '@apollo/client';
import { FaCheckCircle } from "react-icons/fa";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/solid";
// import { useAuth } from "App";
// import jwtDecode from "jwt-decode";
// import { GET_USER_BY_ID } from "graphql/auth/queries";

export const DefaultColumnFilter = ({ column: { filterValue, setFilter } }) => {
  return (
    <input
      value={filterValue || ""}
      onChange={(e) => setFilter(e.target.value)}
      placeholder="Search"
    />
  );
};

const LiveDomainsByVendor = ({ domains }) => {
  const navigate = useNavigate();

  const [loaderOpen, setLoaderOpen] = useState(false);
  // const { token } = useAuth();
  // const user = jwtDecode(token);
  // console.log("User: ", user);
  // console.log(user.sub);
  // const { loading, error, dataUser, refetch } = useQuery(GET_USER_BY_ID, {
  //   variables: { "id": user.sub }, // Replace with the domain you want to query
  // });
  // console.log("Graphql Query: ", GET_USER_BY_ID?.loc?.source?.body);
  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error: {error.message}</p>;
  // console.log(dataUser, error, loading);

  // Define the columns based on your data structure
  const columns = React.useMemo(
    () => [
      {
        Header: "Domain",
        accessor: "title",
        // Filter: DefaultColumnFilter,
      },
      {
        Header: "Price",
        accessor: "price",
      },
      {
        Header: "Handle",
        accessor: "handle",
      },
      // {
      //   Header: "Minimum Offer",
      //   accessor: "minimumOffer",
      // },
      // {
    ],
    []
  );

  // const data = React.useMemo(() => domains, [domains]);

  const data = React.useMemo(() => {
    return domains ? domains : [{}];
  }, [domains]);

  // const data = domains;

  console.log(data, "fghjnkdcmfl========================");

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  console.log("-----------tableinstance--------", tableInstance);

  const {
    // getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Get the current page
    nextPage, // Function to go to the next page
    previousPage, // Function to go to the previous page
    canNextPage, // Boolean indicating if there's a next page
    canPreviousPage, // Boolean indicating if there's a previous page
    pageOptions, // An array of available page numbers
    state: { pageIndex, filters }, // Get the current page index
  } = tableInstance;

  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error: {error.message}</p>;
  // console.log(headerGroups);

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Live Domains
        </div>

        <div className="flex items-center">
          {/* <label htmlFor="search" className="mr-2 text-gray-600">Search:</label> */}
          <input
            type="text"
            id="search"
            value={filters[0]?.value || ""}
            onChange={(e) => {
              tableInstance.setFilter("title", e.target.value); // Apply the filter to the "title" column using tableInstance
            }}
            placeholder="Search domains..."
            className="rounded-md border px-3 py-2 transition duration-300 focus:border-blue-500 focus:outline-none"
          />
        </div>

        {/* <CardMenu /> */}
      </header>

      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
        <table
          // className="min-w-full divide-y divide-gray-300" {...getTableProps()}
          className="w-full"
          variant="simple"
          color="gray-500"
          mb="24px"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, columnIndex) => (
                  <th
                    className="border-b border-gray-200 pb-[10px] pr-8 text-start dark:!border-navy-700"
                    {...column.getHeaderProps(column.getSortByToggleProps())} // Updated line
                    key={columnIndex}
                  >
                    <div>
                      {column.render("Header")}
                      <span className="text-xs">
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " ▼"
                            : " ▲"
                          : " ▲▼"}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="divide-y divide-gray-200" {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, cellIndex) => (
                    <td
                      className="pb-[16px] pt-[14px] sm:text-[14px]"
                      {...cell.getCellProps()}
                      key={cellIndex}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        {page.length === 0 && (
          <tr className="flex h-32  w-full items-center justify-center font-semibold text-red-600">
            No data found
          </tr>
        )}

        <Loader loaderOpen={loaderOpen} setLoaderOpen={setLoaderOpen} />
      </div>
      <div className="mt-8 flex items-center justify-between pb-4">
        <div className="flex items-center justify-between gap-1 md:mt-8 md:pb-4">
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="flex cursor-pointer items-center rounded-lg bg-[#01afef] px-4 py-2 text-sm font-medium text-white"
          >
            <ChevronDoubleLeftIcon className="h-4 w-4 text-white" /> Previous
          </button>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="flex cursor-pointer items-center rounded-lg bg-[#01afef] px-4 py-2 text-sm font-medium text-white"
          >
            Next <ChevronDoubleRightIcon className="h-4 w-4 text-white" />
          </button>
        </div>
        <div>
          Page <strong>{pageIndex + 1}</strong> of{" "}
          <strong>{pageOptions.length}</strong>
        </div>
      </div>
    </Card>
  );
};

export default LiveDomainsByVendor;
