import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import {
  CubeIcon,
  ChatBubbleLeftRightIcon,
  UserGroupIcon,
  IdentificationIcon,
} from "@heroicons/react/24/solid";
const projects = [
  {
    name: "Free logo for any US$1000 order",
    initials: "GA",
    icon: CubeIcon,
    members: "We deliver domains as fast we can",
    bgColor: "bg-pink-600",
  },
  {
    name: "Brandcart Professional Help",
    initials: "CD",
    icon: UserGroupIcon,
    members: "Offer great ownership experience",
    bgColor: "bg-purple-600",
  },
  {
    name: "We are available for you",
    initials: "T",
    icon: ChatBubbleLeftRightIcon,
    members: "Contact us by Chat, Email or Phone",
    bgColor: "bg-yellow-500",
  },
  {
    name: "100% Secure payments",
    initials: "RC",
    icon: IdentificationIcon,
    members: "Creditcards, Escrow & Wire Transfers",
    bgColor: "bg-green-500",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function FourCards() {
  return (
    <div>
      <ul
        role="list"
        className="mt-3 px-4 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4"
      >
        {projects.map((project) => (
          <li
            key={project.name}
            className="col-span-1 flex rounded-md shadow-lg"
          >
            <div
              className={classNames(
                project.bgColor,
                "flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white"
              )}
            >
              <project.icon className="h-10 w-10" aria-hidden="true" />
            </div>
            <div className="flex flex-1 items-center justify-between  rounded-r-md border-b border-r border-t border-gray-200 bg-white">
              <div className="flex-1  px-4 py-2 text-sm">
                <p className="text-lg font-medium text-[#01afef]">
                  {project.name}
                </p>
                <p className="text-gray-500">{project.members}</p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
