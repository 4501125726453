import { createContext, useState } from "react"
import { useMutation } from "@apollo/client"
import { GENERATE_OTP_MUTATION } from "graphql/users/mutations";
import ErrorAlert from "components/ErrorAlert";

const ForgotPasswordFormContext = createContext({})

export const ForgotPasswordFormProvider = ({ children }) => {


    const title = {
        0: 'Forgot Password Step 1',
        1: 'Forgot Password Step 2',
        2: 'Forgot Password Step 3',
    }

    const [generateOTPMuation] = useMutation(GENERATE_OTP_MUTATION);

    const [page, setPage] = useState(0)
    const [error, setError] = useState('');
    // const [verificationFinalStatusData, setVerificationFinalStatusData] = useState([]);

    const [data, setData] = useState({
        // firstName: '',
        // lastName: '',
        email: '',
        otp: '',
        // username: '',
        password: '',
        confirmPassword: '',
        // phoneNumber: '',
        // company: '',
        // addressLine1: '',
        // addressLine2: '',
        // role: ''
    })



    const resendOTPEmail = (e) => {
        e.preventDefault();
        generateOTPMuation({
            variables: {
                email: data.email
            }
        })
            .then((response) => {
                if (response.data.generateOTP) {
                    // setPage(prev => prev + 1)
                } else {
                    throw ("Something went Wrong")
                }
            })
            .catch((error) => {
                // Handle errors here
                console.error("Error Encountered: ", error);
                if (error.graphQLErrors && error.graphQLErrors.length > 0) {
                    const errorMessage = error.graphQLErrors[0]?.message;
                    if (errorMessage) {
                        setError(errorMessage);
                    } else {
                        // Handle other GraphQL errors
                    }
                } else {
                    // Handle non-GraphQL errors (e.g., network errors)
                    setError(error.message);
                }
            })
    }

    const onClose = () => {
        setError('');
    }


    // useEffect(() => {
    //     if (data.sameAsBilling) {
    //         setData(prevData => ({
    //             ...prevData,
    //             shipFirstName: prevData.billFirstName,
    //             shipLastName: prevData.billLastName,
    //             shipAddress1: prevData.billAddress1,
    //             shipAddress2: prevData.billAddress2,
    //             shipCity: prevData.billCity,
    //             shipState: prevData.billState,
    //             shipZipCode: prevData.billZipCode
    //         }))
    //     } else {
    //         setData(prevData => ({
    //             ...prevData,
    //             shipFirstName: "",
    //             shipLastName: "",
    //             shipAddress1: "",
    //             shipAddress2: "",
    //             shipCity: "",
    //             shipState: "",
    //             shipZipCode: ""
    //         }))
    //     }
    // }, [data.sameAsBilling])

    // const handleProductDescriptionChange = e => {
    //     setData(prevData => ({
    //         ...prevData,
    //         description: 
    //     }))
    // }
    // const [description, setDescription] = useState('');


    const handleChange = e => {
        // console.log("data");
        // console.log(e);
        const type = e.target?.type
        // console.log(type);

        const name = e.target?.name
        // console.log(name);
        const value = type === "checkbox"
            ? e.target.checked
            : (name && name === "price") ? parseFloat(e.target.value) : e.target.value;

        // console.log(type, name, value)

        setData(prevData => ({
            ...prevData,
            [name]: value
        }))
        // console.log(data);
    }

    const {
        // billAddress2,
        // sameAsBilling,
        // shipAddress2,
        // optInNews,
        ...requiredInputs } = data

    console.log(data);

    const canSubmit = [...Object.values(requiredInputs)].every(Boolean) && page === Object.keys(title).length - 1

    const canSubmitSetPassword = Object.keys(data)
        .filter(key =>
            key.startsWith('password')
        )
        .map(key => {
            // console.log(key, data[key]);
            return data[key];
        })
        .every(Boolean)


    // const canNextPage2 = Object.keys(data)
    //     .filter(key => key.startsWith('ship') && key !== 'shipAddress2')
    //     .map(key => data[key])
    //     .every(Boolean)

    // const disablePrev = page === 0

    // const disableNext =
    //     (page === Object.keys(title).length - 3)
    //     || (page === 0 && !canSubmitSetPassword)
    // // || (page === 1 && !canNextPage2)

    // const prevHide = page === 0 && "remove-button"

    const nextHide = (page === Object.keys(title).length - 2 || page === Object.keys(title).length - 1) && "remove-button"

    const submitHide = (page === Object.keys(title).length - 3 || page === Object.keys(title).length - 1) && "remove-button"

    const submitPasswordHide = (page === Object.keys(title).length - 2 || page === Object.keys(title).length - 3) && "remove-button"

    return (
        <div>
            {error && <ErrorAlert message={error} onClose={onClose} />}
            {/* // <ForgotPasswordFormContext.Provider value={{ title, page, setPage, data, setData, canSubmit, handleChange, disablePrev, disableNext, prevHide, nextHide, submitHide }}> */}
            <ForgotPasswordFormContext.Provider value={{ title, page, setPage, data, setData, canSubmit, handleChange, nextHide, submitHide, submitPasswordHide, resendOTPEmail }}>
                {children}
            </ForgotPasswordFormContext.Provider>
        </div>
    )
}

export default ForgotPasswordFormContext 