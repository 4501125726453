import Card from "components/card";
import VideoPlayer from "components/video";
import React from "react";
import Lottie from "lottie-react";
import animation from "./animation.json";

const Video = () => {
  return (
    <Card>
      <div className="flex w-full flex-col items-center p-4 md:p-6 ">
        <main className={`mx-[12px] w-full h-full flex flex-col md:flex-row items-center justify-center transition-all md:pr-2`}>
          <div className="w-full md:w-1/2">
            <Lottie
              animationData={animation}
              className="relative z-[5] h-[100%] w-[100%]"
            />
          </div>
          <div className="mt-2 w-full md:w-1/2 flex flex-col items-center text-center">
            <p className="font-semibold">
              Unlock your potential with our engaging video demos.
            </p>
            <p className="font-semibold">
              Expert guides for a seamless journey on your dashboard!
            </p>
            <p className="mt-2 text-xl font-semibold  text-[#00a1fe]">Happy Selling!</p>
            <VideoPlayer />
          </div>
        </main>
      </div>
    </Card>
  );
};

export default Video;
