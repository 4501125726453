// import CheckDomainOwnership from "components/DomainOwnershipCheck/CheckDomainOwnership"
import useAddProductFormContext from "../../../../hooks/useAddProductFormContext"
import AddDomainForm from "./AddDomainForm"
import AddDomainTable from "./AddDomainTable"

const FormInputs = () => {

    const { page } = useAddProductFormContext()

    const display = {
        0: <AddDomainForm />,
        1: <AddDomainTable />,
        // TODO: Check these from here and other places if not needed.
        // 2: <CheckDomainOwnership />,
        // 2: <OptIn />
    }

    const content = (
        <div className="form-inputs">
            {display[page]}
        </div>
    )


    return content
}
export default FormInputs