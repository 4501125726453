// auth queries.js

import { gql } from '@apollo/client';

export const GET_USER_BY_ID = gql`
  query GetUserById($id: Float!){
    getUserById(id:$id){
      email
      first_name
      last_name
      phone_number
      merchant_id
      isEmailVerified
      country
    }
  }
`;

export const RESEND_VERIFICATION_EMAIL = gql`
  query resendVerificationEmail($email:String!) {
    resendVerificationEmail(email:$email)
  }
`;
