/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useState } from "react";

import { useMutation } from "@apollo/client";
// import { ADD_PRODUCT_MUTATION } from 'graphql/products/queries';

import useAddProductFormContext from "hooks/useAddProductFormContext";
import FormInputs from "./FormInputs";
import Card from "components/card";
// import { AiOutlineDownload, AiOutlineFile } from 'react-icons/ai';
import { useNavigate } from "react-router-dom";
// import ConfirmationModal from 'components/Modals/ConfirmationModal';
import SuccessModal from "components/Modals/SuccessModal";
import { CREATE_PRODUCT_MUTATION } from "graphql/products/mutations";
import ErrorAlert from "components/ErrorAlert";
import { useAuth } from "App";
import jwtDecode from "jwt-decode";
import { CREATE_NOTIFICATION_MUTATION } from "graphql/notifications/mutations";
import VideoPlayer from "components/video";
import Video from "./Video/video";
import AddDomainAnimation from "./New/AddDomainAnimatio";

export default function AddProduct() {
  const [createProduct, { productCreateError, productCreateLoading }] =
    useMutation(CREATE_PRODUCT_MUTATION);
  const [createNotification] = useMutation(CREATE_NOTIFICATION_MUTATION);
  // console.log("Graphql Query: ", CREATE_PRODUCT_MUTATION?.loc?.source?.body);

  const [error, setError] = useState();
  // const [showError, setShowError] = useState(false)

  const { token } = useAuth();
  const user = jwtDecode(token);

  const {
    // page,
    setPage,
    data,
    // title,
    // canSubmit,
    disablePrev,
    disableNext,
    prevHide,
    nextHide,
    submitHide,
    validateDomain,
    setData,
  } = useAddProductFormContext();

  const handlePrev = () => setPage((prev) => prev - 1);

  const handleNext = () => setPage((prev) => prev + 1);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Update the minimumOffer property for all domains
    const updatedDomainList = data.domainList.map((domain) => ({
      ...domain,
      minimumOffer: domain.minimumOffer < 20 ? 20 : domain.minimumOffer,
    }));

    // Update the context data with the updated domainList
    setData((prevData) => ({
      ...prevData,
      domainList: updatedDomainList,
    }));

    try {
      const transformedDomainList = data.domainList.map((domain) => ({
        rawDomainsList: data.rawDomainsList,
        word: domain.word,
        value: domain.value,
        checked: domain.checked,
        buyNowPrice: domain.buyNowPrice,
        floorPrice: domain.floorPrice,
        minimumOffer: domain.minimumOffer,
        status: "Not-Approved", // Assuming isApproved is always false
        createdByUserId: user.sub,
      }));
      // console.log("TransformedDomainList: ", transformedDomainList);
      const response = await createProduct({
        variables: {
          createProductInput: transformedDomainList,
        },
      });

      console.log('-----Response: ', response)

      if (response.data) {
        var createNotificationRes = await createNotification({
          variables: {
            userId: user.sub,
            title: `Domains Uploaded`,
            message: "Your upload was successfull",
          },
        });
        console.log(createNotificationRes.data);
        if (createNotificationRes.data) {
          navigate("/portfolio/my_domains");
        }
      }

      // console.log('Password saved:', response);
    } catch (error) {
      console.log(error);
      // setError("Domain List not valid OR All the fields are not required");
      if (error.message.includes('duplicate key value violates')) {
        // Set a custom error message for duplicate key violation
        setError(' Some domains already exist. Please check your domain list.');
      } else if (error.message.includes('All the fields are required')) {
        setError('All the fields are required')
      } else if (error.message.includes('already exist in Brandcart.')) {
        setError(error.message);
      } else {
        // Handle other errors
        setError(' An error occurred while uploading domains.');
      }
    }
    // console.log(value);
  };

  // // Check for Relevance
  // const [productData, handleProductDataChange] = useState({});

  // const [name, setName] = useState('');
  // // if (tags) {
  // //   console.log(tags);
  // // }
  // const [price, setPrice] = useState(0.0);
  // const [description, setDescription] = useState('');
  // const [collectionsToJoin, setCollectionsToJoin] = useState(["gid://shopify/Collection/448911442215"]);
  // const [tags, setTags] = useState([]);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  // List of suggestion strings

  // console.log(name, price, description, collectionsToJoin, tags)

  // const [addProduct, { loading, error }] = useMutation(ADD_PRODUCT_MUTATION);

  // /////////////////////////////////////////////////////////////

  // const handleAddProduct = () => {
  //   addProduct({
  //     variables: { name, price, description, collectionsToJoin, tags },
  //   })
  //     .then((result) => {
  //       // Handle success, e.g., show a success message
  //       console.log('Product added:', result.data.addProduct);
  //     })
  //     .catch((err) => {
  //       // Handle error, e.g., display an error message
  //       console.error('Error adding product:', err);
  //     });
  // };

  const isAllDomainsValid = (domainList) => {
    // console.log(domainList)
    return domainList.every((domain) => validateDomain(domain.word));
  };

  const onClose = () => {
      setError('');
  }

  if (productCreateLoading) return <p>Loading...</p>;
  if (productCreateError) return <p>Error: {productCreateError.message}</p>;

  return (
    // <form className="form flex-col" onSubmit={handleSubmit}>

    //   <header className="form-header">
    //     <h2>{title[page]}</h2>

    //     <div className="button-container">

    //       <button type="button" className={`button ${prevHide}`} onClick={handlePrev} disabled={disablePrev}>Prev</button>

    //       <button type="button" className={`button ${nextHide}`} onClick={handleNext} disabled={disableNext}>Next</button>

    //       <button type="submit" className={`button ${submitHide}`} disabled={!canSubmit}>Submit</button>
    //     </div>
    //   </header>

    <>
      {error && <ErrorAlert message={error} onClose={onClose} />}
      <Card extra={"w-full h-full p-4"}>
        <div className="relative items-center justify-between">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Add Domains
          </div>
          {/* <CardMenu /> */}
        </div>
        <div className="flex w-full flex-col md:flex-row">
          <div className="flex w-full flex-col ">
            <FormInputs />
            <div className="flex items-center justify-between gap-10 px-3 py-5 ">
              {/* <button type="submit" className="inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900 hover:bg-blue-800">
            Post comment
            </button> */}
              <div className="flex flex-col items-center justify-between gap-4 px-3 py-5 md:flex-row">
                {/* <button className="flex items-center gap-3 mr-3 border-solid border-2 border-gray-900 px-6 py-2 cursor-pointer">
                  <span><AiOutlineDownload /></span>
                  <span>Download Template</span>
                </button>
                <button className="flex items-center gap-3 mr-3 border-solid border-2 border-gray-900  px-6 py-2 cursor-pointer">
                  <span><AiOutlineFile /></span>
                  <span>Upload File</span>
                </button> */}
              </div>
              <div className="flex flex-col items-center justify-between gap-4 border-gray-600 px-3 py-5 md:flex-row">
                {/* <button
                  type="button"
                  className={`flex items-center gap-3 mr-3 border-solid border-2 border-gray-900 px-6 py-2 cursor-pointer`}
                >
                  Clear All
                </button> */}
                <button
                  type="button"
                  className={`button mr-3 flex cursor-pointer items-center gap-3 border-2  rounded-md border-none bg-[#00a1fe] hover:bg-[#009ad6] text-white font-semibold border-gray-900 px-6 py-2 ${prevHide}`}
                  disabled={disablePrev}
                  onClick={handlePrev}
                >
                  Prev
                </button>
                <button
                  type="button"
                  className={`button mr-3 rounded-md border-none bg-[#00a1fe] hover:bg-[#009ad6] text-white font-semibold flex cursor-pointer items-center gap-3 border-2   border-gray-900 px-6 py-2 ${nextHide}`}
                  disabled={disableNext}
                  onClick={handleNext}
                >
                  Next
                </button>
                <button
                  type="button"
                  className={`button mr-3 flex cursor-pointer items-center gap-3 border-2  rounded-md border-none bg-[#00a1fe] hover:bg-[#009ad6] text-white font-semibold border-gray-900 px-6 py-2 ${submitHide}`}
                  disabled={!isAllDomainsValid(data.domainList)}
                  onClick={handleSubmit}
                >
                  Submit
                </button>

                <SuccessModal
                  data={{
                    openSuccessModal: openSuccessModal,
                    setOpenSuccessModal: setOpenSuccessModal,
                  }}
                />
                {/* <button type="button" className={`button ${prevHide}`} onClick={handlePrev} disabled={disablePrev}>Prev</button>

              <button type="button" className={`button ${nextHide}`} onClick={handleNext} disabled={disableNext}>Next</button>

              <button type="submit" className={`button ${submitHide}`} disabled={!canSubmit}>Submit</button> */}
              </div>
            </div>
          </div>
          <div className="flex w-full  items-center p-4 md:p-6 lg:w-1/2">
            <AddDomainAnimation />
            {/* <Video/> */}

            {/* <main className={`mx-[12px] h-full flex-none transition-all md:pr-2`}>
              <div className="mt-2">
                <p className="">Unlock your potential with our engaging video demos.</p>
                <p className="">Expert guides for a seamless journey on your dashboard!</p>
                <p className="mt-5">Happy Selling!</p>
                <VideoPlayer />
              </div>
            </main> */}
          </div>
        </div>
      </Card>
      <div className="mt-6">
        <Video />
      </div>

    </>

    // </form>
  );
}
