// import MiniCalendar from "components/calendar/MiniCalendar";
// import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
// import TotalSpent from "views/admin/default/components/TotalSpent";
// import PieChartCard from "views/admin/default/components/PieChartCard";
// import { IoMdHome } from "react-icons/io";
// import { IoDocuments } from "react-icons/io5";
import { MdBarChart } from "react-icons/md";

// import { columnsDataCheck, columnsDataComplex } from "./variables/columnsData";

import Widget from "components/widget/Widget";
// import CheckTable from "views/admin/default/components/CheckTable";
// import ComplexTable from "views/admin/default/components/ComplexTable";
// import DailyTraffic from "views/admin/default/components/DailyTraffic";
// import TaskCard from "views/admin/default/components/TaskCard";
// import tableDataCheck from "./variables/tableDataCheck.json";
// import tableDataComplex from "./variables/tableDataComplex.json";
import jwtDecode from "jwt-decode";
import { useAuth } from "App";
import { useMutation } from "@apollo/client";
import { GET_ADMIN_SALES_THIS_MONTH } from "graphql/analytics/queries";
import { useEffect, useState } from "react";
import { GET_ADMIN_SALES_LAST_THREE_MONTHS } from "graphql/analytics/queries";

const Dashboard = () => {
  const { token } = useAuth()
  const user = jwtDecode(token);

  const [adminSalesThisMonth, setAdminSalesThisMonth] = useState('');
  const [adminSalesLastThreeMonths, setAdminSalesLastThreeMonths] = useState('');

  const [getAdminSalesThisMonth] = useMutation(GET_ADMIN_SALES_THIS_MONTH);
  const [getAdminSalesLastThreeMonths] = useMutation(GET_ADMIN_SALES_LAST_THREE_MONTHS);

  // console.log("GraphQL Query:", GET_ADMIN_SALES_THIS_MONTH?.loc?.source?.body);
  // console.log("GraphQL Query 3 months:", GET_ADMIN_SALES_LAST_THREE_MONTHS?.loc?.source?.body);

  useEffect(() => {
    // Use an effect to call the mutation when the component is mounted
    getAdminSalesThisMonth()
      .then((result) => {
        setAdminSalesThisMonth(result.data.getAdminSalesThisMonth.amount);
      })
      .catch((error) => {
        console.log("Error Fetching Data: ", error.message);
      });

      getAdminSalesLastThreeMonths()
      .then((result) => {
        // console.log(result.data)
        setAdminSalesLastThreeMonths(result.data.getAdminSalesLastThreeMonths.amount);
      })
      .catch((error) => {
        console.log("Error Fetching Data: ", error.message);
      });

  }, []);


  return (
    <div>
      {/* Card widget */}

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        {/* <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Earnings"}
          subtitle={"$340.5"}
        />
        <Widget
          icon={<IoDocuments className="h-6 w-6" />}
          title={"Spend this month"}
          subtitle={"$642.39"}
        /> */}
        {user.roles.includes('Admin') ? (
          <>
            <Widget
              icon={<MdBarChart className="h-7 w-7" />}
              title={"Sales This Month"}
              subtitle={adminSalesThisMonth}
            />

            <Widget
              icon={<MdBarChart className="h-7 w-7" />}
              title={"Sales Last 3 Months"}
              subtitle={adminSalesLastThreeMonths}
            />
          </>
        ) : (
          <>
            <Widget
              icon={<MdBarChart className="h-7 w-7" />}
              title={"Sales This Month"}
              subtitle={adminSalesThisMonth}
            />

            <Widget
              icon={<MdBarChart className="h-7 w-7" />}
              title={"Sales Last 3 Months"}
              subtitle={adminSalesLastThreeMonths}
            />
          </>
        )}
        {/* <Widget
          icon={<MdDashboard className="h-6 w-6" />}
          title={"Your Balance"}
          subtitle={"$1,000"}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"New Tasks"}
          subtitle={"145"}
        />
        <Widget
          icon={<IoMdHome className="h-6 w-6" />}
          title={"Total Projects"}
          subtitle={"$2433"}
        /> */}
      </div>

      {/* Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        {/* <TotalSpent /> */}
        {/* <WeeklyRevenue /> */}
      </div>

      {/* Tables & Charts */}

      {/* <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        <div>
          <CheckTable
            columnsData={columnsDataCheck}
            tableData={tableDataCheck}
          />
        </div>

        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <DailyTraffic />
          <PieChartCard />
        </div>

        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />

        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <TaskCard />
          <div className="grid grid-cols-1 rounded-[20px]">
            <MiniCalendar />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Dashboard;
