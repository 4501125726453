import React, { useEffect } from 'react';

const ErrorAlert = ({ message, onClose, autoCloseDelay = 20000 }) => {
  useEffect(() => {
    // Set up a timer to automatically close the alert after the specified delay
    const timerId = setTimeout(() => {
      if (onClose) {
        onClose();
      }
    }, autoCloseDelay);

    // Clear the timer when the component is unmounted
    return () => clearTimeout(timerId);
  }, [onClose, autoCloseDelay]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4 mt-4" role="alert">
      <strong className="font-bold">Error! </strong>
      <span className="block sm:inline">{message}</span>
      {onClose && (
        <button
          className="absolute top-0 right-0 px-4 py-3"
          onClick={handleClose}
        >
          {/* You can replace the '×' character with your preferred close icon */}
          ×
        </button>
      )}
    </div>
  );
};

export default ErrorAlert;
