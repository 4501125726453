import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client';
// import { APPROVE_DOMAIN_BY_ID_MUTATION } from 'graphql/products/mutations';
import { GET_NON_APPROVED_DOMAINS } from 'graphql/products/queries';
import ApproveDomainsTable from './components/ApproveDomainsTable';
import ApproveConfirmationModal from 'components/Modals/ApproveConfirmationModal';
import RejectConfirmationModal from 'components/Modals/RejectConfirmationModal';
import { APPROVE_DOMAINS_BY_IDS_MUTATION } from 'graphql/products/mutations';
import { REJECT_DOMAINS_BY_IDS_MUTATION } from 'graphql/products/mutations';
import { useNavigate } from 'react-router-dom';
import Notification from 'components/Notification/Notification';

// const CustomModal = ({ show, handleClose, handleConfirm }) => {
//     const [notes, setNotes] = useState("");

//     if (!show) {
//         return null;
//     }

//     return (
//         <div className="modal">
//             <div className="modal-content">
//                 <span className="close" onClick={handleClose}>
//                     &times;
//                 </span>
//                 <h2>Confirm Approval</h2>
//                 <p>Enter any additional notes:</p>
//                 <textarea
//                     value={notes}
//                     onChange={(e) => setNotes(e.target.value)}
//                     placeholder="Add notes..."
//                 />
//                 <button onClick={() => handleConfirm(notes)}>Approve</button>
//                 <button onClick={handleClose}>Cancel</button>
//             </div>
//         </div>
//     );
// };

export default function ApproveDomains() {

    const navigate = useNavigate();
    const [showNotification, setShowNotification] = useState({ status: false, value: "" });

    const [showApproveModal, setShowApproveModal] = useState(false);
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    // const [selectedDomainIds, setSelectedDomainIds] = useState({});

    const { loading, error, data } = useQuery(GET_NON_APPROVED_DOMAINS);
    // const [approveDomain] = useMutation(APPROVE_DOMAIN_BY_ID_MUTATION);
    const [approveDomainsByIds] = useMutation(APPROVE_DOMAINS_BY_IDS_MUTATION);
    const [rejectDomainsByIds] = useMutation(REJECT_DOMAINS_BY_IDS_MUTATION);

    var domains = null;
    // console.log("GraphQL Query:", APPROVE_DOMAIN_BY_ID_MUTATION?.loc?.source?.body, GET_NON_APPROVED_DOMAINS?.loc?.source?.body, APPROVE_DOMAINS_BY_IDS_MUTATION?.loc?.source?.body);

    // const handleApprove = (domainId) => {
    //     // Show a confirmation dialog (you can use a modal library like react-modal)
    //     const isConfirmed = window.confirm("Are you sure you want to approve this domain?");

    //     if (isConfirmed) {
    //         approveDomain({ variables: { id: domainId } })
    //             .then((result) => {
    //                 // Handle the success response
    //                 refetch();
    //                 // console.log("Domain approved successfully:", result);
    //             })
    //             .catch((error) => {
    //                 // Handle any errors
    //                 console.error("Error approving domain:", error);
    //             });
    //     }
    // };

    // const handleRejectAll = (domainId) => {
    //     // Handle the reject action here
    //     console.log("Rejected Domain ID:", domainId);
    // };

    // const handleApproveAll = () => {
    //     // Perform the approve action on all selected domains
    //     console.log("Selected Domains:", selectedRowIds);
    //     // Implement the logic for bulk approval here
    //     setselectedRowIds([]);
    //     setShowApproveModal(false);
    // };

    // const handleApprove = (domainId) => {
    //     // Open the modal for confirmation
    //     setShowApproveModal(true);

    //     // Add the domainId to selectedRowIds
    //     setselectedRowIds((prevselectedRowIds) => [...prevselectedRowIds, domainId]);
    //     console.log(selectedRowIds)
    // };










    const handleApprove = (selectedIds) => {
        // Open the modal for confirmation
        setShowApproveModal(true);

        // Add the domainIds to selectedRowIds
        setSelectedRowIds((prevselectedRowIds) => [
            ...prevselectedRowIds,
            ...selectedIds,
        ]);
        console.log(selectedRowIds);
    };

    const handleConfirmation = (notes) => {

        // const selectedRows = Object.keys(selectedRowIds).map((id) => {
        //     const foundRow = tableInstance.rows.find((row) => row.id === id);
        //     return foundRow ? foundRow.original : null;
        // });
        // const ids = selectedRows.map((row) => row.id); // Extracting ids from selected rows
        // setDomainIds(ids); // Setting the domainIds state
        // Perform the action on selectedRowIds and notes
        // console.log("Selected Domains:", selectedRowIds);
        // console.log("Notes:", notes);

        // Perform the mutation or action here
        approveDomains(selectedRowIds, notes);

        // // Close the modal
        // setShowApproveModal(false);

        // // Clear the selectedRowIds
        // setSelectedRowIds([]);
    };

    const handleRejection = (notes) => {
        // Perform the action on selectedRowIds and notes
        // console.log("Selected Domains:", selectedRowIds);
        // console.log("Notes:", notes);

        // Perform the mutation or action here
        rejectDomains(selectedRowIds, notes);

        // Close the modal
        // setShowApproveModal(false);

        // // Clear the selectedRowIds
        // setSelectedRowIds([]);
    };

    const approveDomains = async (domainIds, notes) => {
        if (domainIds) {
            // console.log(domainIds);
            // console.log(notes);
            const ids = domainIds.map(item => item.id);
            // console.log(ids);

            try {
                const result = await approveDomainsByIds({ variables: { ids, notes } });

                if (result.data) {
                    console.log(result);
                    setShowApproveModal(false);
                    setShowNotification({ status: true, value: "Domains Approved Successfully" });

                    setTimeout(() => {
                        navigate('/portfolio');
                        setShowNotification({ status: false, value: "" });
                    }, 2000);
                } else {
                    // Handle case where result.data is falsy
                }
            } catch (error) {
                console.error("Error Approving Domains: ", error.message);
                // Handle error as needed
            }
        }
        // Logic to approve multiple domains
        // domainIds.forEach((domainId) => {
        //     // Perform the approval action for each domain
        //     approveDomain({ variables: { id: domainId } })
        //         .then((result) => {
        //             // Handle the success response
        //             refetch();
        //             console.log("Domain approved successfully:", result);
        //         })
        //         .catch((error) => {
        //             // Handle any errors
        //             console.error("Error approving domain:", error);
        //         });
        // });
    };

    const rejectDomains = async (domainIds, notes) => {
        if (domainIds) {
            // console.log(domainIds);
            // console.log(notes);
            const ids = domainIds.map(item => item.id);
            // console.log(ids);

            try {
                const result = await rejectDomainsByIds({ variables: { ids, notes } });

                if (result.data) {
                    console.log(result);
                    setShowApproveModal(false);
                    setShowNotification({ status: true, value: "Domains Rejected Successfully" });

                    setTimeout(() => {
                        navigate('/portfolio/rejected_domains');
                        setShowNotification({ status: false, value: "" });
                    }, 2000);
                } else {
                    // Handle case where result.data is falsy
                }
            } catch (error) {
                console.error("Error Rejecting Domains: ", error.message);
                // Handle error as needed
            }
        }
        // Logic to approve multiple domains
        // domainIds.forEach((domainId) => {
        //     // Perform the approval action for each domain
        //     approveDomain({ variables: { id: domainId } })
        //         .then((result) => {
        //             // Handle the success response
        //             refetch();
        //             console.log("Domain approved successfully:", result);
        //         })
        //         .catch((error) => {
        //             // Handle any errors
        //             console.error("Error approving domain:", error);
        //         });
        // });
    };

    //...

    // const handleApproveAll = () => {

    //     // Get all the selected domain IDs
    //     // const selectedIds = Object.keys(selectedRowIds);
    //     console.log(selectedRowIds);
    //     // const selectedIds = Object.keys(selectedRowIds);

    //     // Perform the approve action on all selected domains
    //     console.log("Selected Domains:", selectedRowIds);
    //     // Implement the logic for bulk approval here
    //     handleApprove(selectedIds);
    //     setShowApproveModal(false);
    // };


    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    domains = data.findNonApprovedDomains;

    return (
        <>
            {showNotification.status &&
                <Notification showNotification={showNotification} setShowNotification={setShowNotification} />
            }
            <div className="mt-5 grid h-full overflow-x-scroll">
                {/* <div className="mt-5 grid h-full"> */}
                    <ApproveDomainsTable
                        domains={domains}
                        handleApprove={handleApprove}
                        // handleRejectAll={handleRejectAll}
                        // handleApproveAll={handleApproveAll}
                        setSelectedRowIds={setSelectedRowIds}
                        setShowApproveModal={setShowApproveModal}
                        setShowRejectModal={setShowRejectModal}
                    />
                    <ApproveConfirmationModal
                        show={showApproveModal}
                        handleClose={() => setShowApproveModal(false)}
                        handleConfirm={handleConfirmation}
                    />
                    <RejectConfirmationModal
                        show={showRejectModal}
                        handleClose={() => setShowRejectModal(false)}
                        handleConfirm={handleRejection}
                    />
                </div>
            {/* </div> */}
        </>
    )
}