import { useState } from "react";

const ApproveConfirmationModal = ({ show, handleClose, handleConfirm }) => {
    const [notes, setNotes] = useState("");

    if (!show) {
        return null;
    }

    return (
        <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
            <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>

            <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">

                <div className="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50" onClick={handleClose}>
                    <svg className="fill-current text-white" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                        <path d="M18 1.5L16.5 0 9 7.5 1.5 0 0 1.5 7.5 9 0 16.5 1.5 18 9 10.5 16.5 18 18 16.5 10.5 9 18 1.5z"></path>
                    </svg>
                </div>

                <div className="modal-content py-4 text-left px-6">
                    <div className="flex justify-between items-center pb-3">
                        <p className="text-2xl font-bold">Confirm Approval</p>
                    </div>

                    <p>Enter any additional notes:</p>
                    <textarea
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        placeholder="Add notes..."
                        className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none my-1"
                    />

                    <div className="flex justify-end pt-2">
                        <button onClick={() => handleConfirm(notes)} className="px-4 py-2 bg-green-500 text-white rounded-md mr-2">Approve</button>
                        <button onClick={handleClose} className="px-4 py-2 bg-red-500 text-white rounded-md">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApproveConfirmationModal;
