import React, { useState } from 'react'

import Card from "components/card";
// import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// import TagsInput from 'components/TagsInput';
// import useAddProductFormContext from 'hooks/useAddProductFormContext';
// import { ADD_PRODUCT_MUTATION } from 'graphql/products/queries';

// import { gql } from '@apollo/client';
// import 'flowbite';
import { useMutation } from '@apollo/client';
// import VerificationConfirmationModal from 'components/DomainOwnershipCheck/VerificationConfirmationModal';
// import VerificationFailedModal from 'components/DomainOwnershipCheck/VerificationFailedModal';
// import VerificationSuccessModal from 'components/DomainOwnershipCheck/VerificationSuccessModal';
// import Loader from 'components/Loader';
// import ImageUpload from 'components/ImageUpload/ImageUpload';

import { useParams } from 'react-router-dom';
import { GET_DOMAIN_BY_ID } from 'graphql/products/mutations';


// const VERIFY_DOMAIN_OWNERSHIP = gql`
//   mutation VerifyDomainOwnership($domain: String!) {
//     verifyDomainOwnership(domain: $domain) {
//       status
//     }
//   }
// `;

// const CREATE_TXT_RECORD = gql`
//   mutation CreateTxtRecord(
//       $domain: String!
//     ) {
//     createTxtRecord(
//         domain: $domain
//     ) {
//       id,
//       randomString
//     }
//   }
// `;

function EditDomain() {

  // const navigate = useNavigate();

  // CUSTOM STATE: Getting Data and handleChange from Form Context
  // const { data, handleChange } = useAddProductFormContext()
  const [data, setData] = useState({});
  const handleChange = () => {

  }


  // const [addProduct, { loading, error }] = useMutation(ADD_PRODUCT_MUTATION);
  const [findProductById] = useMutation(GET_DOMAIN_BY_ID);

  const params = useParams()
  // console.log(params);

  findProductById({
    variables: {
      "id": parseInt(params['*']),
    }
  })
    .then((result) => {
      // console.log("hello")
      // Handle success, e.g., show a success message
      setData(result.data.findDomainById)
      // console.log('Product added:', result.data.findDomainById);
      // setTimeout(() => {
      //   setOpenVerificationSuccessModal(false);
      //   navigate('/portfolio')
      // }, 2000);
    })
    .catch((err) => {
      // Handle error, e.g., display an error message
      console.error('Error adding product:', err);
    });

  // /**
  //  * CUSTOM FUNCTION
  //  * Handling the Change in the Description wysywig editor and setting the description value in the Form Context data state variable
  //  */
  // const handleEditorChange = (content) => {
  //   // const data = {
  //   //   target: {
  //   //     type: 'html',
  //   //     name: 'description',
  //   //     value: content
  //   //   }
  //   // }
  //   // handleChange(data);
  // };

  /**
   * CUSTOM FUNCTION
   * Handles Submit of Add Domain Form. Need to get verification of domain done in between.
   * @param e A JavaScript event object.
   */
  // const handleSubmit = async () => {
  //   console.log("'''''''''''''''''Final")
  //   console.log(data)
  //   await addProduct({
  //     variables: {
  //       createProductInput: {
  //         title: data.productTitle,
  //         price: data.price,
  //         domain: data.productDomain,
  //         media: data.media,
  //         description: data.description,
  //         collectionsToJoin: data.collectionsToJoin,
  //         tags: data.productTags
  //       },
  //     }
  //   })
  //     .then((result) => {
  //       console.log("hello")
  //       // Handle success, e.g., show a success message
  //       console.log('Product added:', result.data.createProduct);
  //       setTimeout(() => {
  //         setOpenVerificationSuccessModal(false);
  //         navigate('/portfolio')
  //       }, 2000);
  //     })
  //     .catch((err) => {
  //       // Handle error, e.g., display an error message
  //       console.error('Error adding product:', err);
  //     });
  // }

  /**
   * Working on this part of code
   */
  // const [domain, setDomain] = useState();
  // const [loading, setLoading] = useState(false);
  // const [verificationRecord, setVerificationRecord] = useState('');
  // const [verificationStatus, setVerificationStatus] = useState('');
  // const [loaderOpen, setLoaderOpen] = useState(false);
  // const [openVerificationConfirmationModal, setOpenVerificationConfirmationModal] = useState(false);
  // const [openVerificationFailedModal, setOpenVerificationFailedModal] = useState(false);
  // const [openVerificationSuccessModal, setOpenVerificationSuccessModal] = useState(false);
  // const [verificationFinalStatusData, setVerificationFinalStatusData] = useState(false);

  // Destructure the mutation function and loading state from the useMutation hook
  // const [createTxtRecord, { loadingCreateTxtRecord }] = useMutation(CREATE_TXT_RECORD);
  // const [verifyDomainOwnership, { loadingVerificationData }] = useMutation(VERIFY_DOMAIN_OWNERSHIP);

  const handleCreateTxtRecord = () => {

    // console.log("hello");
    // Call the mutation function
    // const submitData = createTxtRecord({
    //   variables: {
    //     domain: data.productDomain,
    //   }
    // }
    // ).then((res) => {
    //   if (res.data) {
    //     console.log(res.data.createTxtRecord)
    //     // const verificationRecord = res.data.createTxtRecord.randomString;
    //     // console.log(verificationRecord)
    //     setVerificationRecord(res.data.createTxtRecord.randomString);
    //     setOpenVerificationConfirmationModal(true);
    //     setVerificationStatus('pending');
    //   } else {
    //     throw ("Something went Wrong");
    //   }
    // }).catch((err) => {
    //   console.log(err);
    // })
    // createTxtRecord({
    //   variables: { domain },
    // })
    //   .then((response) => {
    //     console.log('Mutation response:', response.data);
    //     // Do something with the response if needed
    //   })
    //   .catch((error) => {
    //     console.error('Mutation error:', error);
    //     // Handle any errors that occurred during the mutation
    //   });
  };

  // const handleVerificationStatus = () => {
  //   setLoaderOpen(true);
  //   let elapsedTime = 0; // Initialize elapsed time
  //   const interval = setInterval(() => {
  //     elapsedTime += 5000; // Increment elapsed time by 5 seconds
  //     const submitData = verifyDomainOwnership({
  //       variables: {
  //         domain: data.productDomain,
  //       }
  //     }
  //     ).then((res) => {
  //       if (res.data) {
  //         const status = res.data.verifyDomainOwnership.status;
  //         // setVerificationRecord(res.data.verifyDomainOwnership.verificationRecord);
  //         // setOpenVerificationConfirmationModal(true);
  //         // setVerificationStatus('pending');
  //         // console.log(response);
  //         // const { status } = response.data;
  //         // console.log(response.data);
  //         setVerificationStatus(status);
  //         if (status === 'verified') {
  //           setLoaderOpen(true);
  //           clearInterval(interval);
  //           setOpenVerificationConfirmationModal(false);
  //           setOpenVerificationSuccessModal(true)
  //           setVerificationFinalStatusData(true)
  //           handleSubmit();
  //         } else if (status === 'failed') {
  //           setLoaderOpen(false);
  //           clearInterval(interval);
  //           setOpenVerificationConfirmationModal(false);
  //           setOpenVerificationFailedModal(true);
  //         } else {
  //           console.log(true);
  //           setLoaderOpen(true);
  //         }
  //       } else {
  //         throw ("Something went Wrong");
  //       }
  //       // Check if elapsed time is greater than 3 minutes (180000 ms)
  //       if (elapsedTime > 180000) {
  //         clearInterval(interval);
  //         setLoaderOpen(false);
  //         setOpenVerificationConfirmationModal(false);
  //         setOpenVerificationFailedModal(true);
  //       }
  //     }).catch((err) => {
  //       console.log(err);
  //     })
  //   }, 5000);

  // }

  // const handleDomainChange = (event) => {
  //     setDomain(event.target.value);
  // };

  return (
    <Card extra={"w-full h-full p-4 mt-10 pb-0"}>
      <img src="https://capsule-render.vercel.app/api?type=rounded&color=auto&height=300&section=header&text=capsule%20render&fontSize=90" alt='Capsule'/>
      <form>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">Edit Product</h2>
            {/* <p className="mt-1 text-sm leading-6 text-gray-600">
                            This information will be displayed publicly so be careful what you share.
                        </p> */}

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              {/* <div className="sm:col-span-4">
                        <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                            Username
                        </label>
                        <div className="mt-2">
                            <div
                                className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                <span
                                    className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">workcation.com/</span>
                                <input type="text" name="username" id="username" autoComplete="username"
                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                    placeholder="janesmith" />
                            </div>
                        </div>
                    </div> */}

              {/* <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6"> */}
              {/* <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                  Title
                </label>
                <div className="mt-2">
                  <input type="text" name="productTitle" id="productTitle" autoComplete="productTitle"
                    value={data.name} onChange={handleChange}
                    className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1
                                ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  />
                </div>
              </div> */}
              {/*
                    </div> */}
              <div className="sm:col-span-3">
                <label htmlFor="productDomain" className="block text-sm font-medium leading-6 text-gray-900">
                  Domain
                </label>
                <div className="mt-2">
                  <input type="text" name="productDomain" id="productDomain" autoComplete="productDomain"
                    value={data.word} onChange={handleChange}
                    className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1
                                ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              {/* <div className="col-span-full">
                <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                  Description
                </label>
                <div className="mt-2">
                  <ReactQuill theme="snow" type="" value={data.description} onChange={handleEditorChange} />
                </div>
                <p className="mt-3 text-sm leading-6 text-gray-600">Write a few sentences about yourself.</p>
              </div> */}

              {/* <ImageUpload /> */}

              {/* <div className="col-span-full">
                        <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                            Photo
                        </label>
                        <div className="mt-2 flex items-center gap-x-3">
                            <UserCircleIcon className="h-12 w-12 text-gray-300" aria-hidden="true" />
                            <button type="button"
                                className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                Change
                            </button>
                        </div>
                    </div> */}
            </div>
          </div>

          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">Pricing</h2>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                  Buy Now Price
                </label>
                <div className="relative mt-2 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input type="text" name="price" id="price" value={data.buyNowPrice} onChange={handleChange}
                    className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1
                            ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                            focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="0.00"
                    aria-describedby="price-currency"
                  />
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                      USD
                    </span>
                  </div>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                  Floor Price
                </label>
                <div className="relative mt-2 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input type="text" name="price" id="price" value={data.floorPrice} onChange={handleChange}
                    className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1
                            ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                            focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="0.00"
                    aria-describedby="price-currency"
                  />
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                      USD
                    </span>
                  </div>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                  Minimum Offer
                </label>
                <div className="relative mt-2 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input type="text" name="price" id="price" value={data.minimumOffer} onChange={handleChange}
                    className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1
                            ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                            focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="0.00"
                    aria-describedby="price-currency"
                  />
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <span className="text-gray-500 sm:text-sm" id="price-currency">
                      USD
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="col-span-full">
                <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                  Tags
                </label>
                <div className="mt-2">
                  <TagsInput tags={data.tags} setTags={handleChange} />
                </div>
              </div> */}

              {/* <div className="sm:col-span-3">
                        <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                            Compare At Price
                        </label>
                        <div className="mt-2">
                            <input type="text" name="compare-at-price" id="compare-at-price"
                                autoComplete="compare-at-price"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div> */}

              {/* <div className="sm:col-span-4">
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            Cost Per Item
                        </label>
                        <div className="mt-2">
                            <input id="cost-per-item" name="cost-per-item" type="cost-per-item"
                                autoComplete="cost-per-item"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div> */}
            </div>
          </div>

          {/* <div className="border-b border-gray-900/10 pb-12">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Meta Fields</h2>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-6">
                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                            BrandClix
                        </label>
                        <div className="mt-2">
                            <input type="text" name="price" id="price" autoComplete="price"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>

                    <div className="sm:col-span-6">
                        <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                            Related Industries List
                        </label>
                        <div className="mt-2">
                            <input type="text" name="compare-at-price" id="compare-at-price"
                                autoComplete="compare-at-price"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>

                    <div className="sm:col-span-6">
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            Short Description
                        </label>
                        <div className="mt-2">
                            <input id="cost-per-item" name="cost-per-item" type="cost-per-item"
                                autoComplete="cost-per-item"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>

                    <div className="sm:col-span-6">
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            Ratings
                        </label>
                        <div className="mt-2">
                            <input id="cost-per-item" name="cost-per-item" type="cost-per-item"
                                autoComplete="cost-per-item"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>

                    <div className="sm:col-span-6">
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            Industry Category
                        </label>
                        <div className="mt-2">
                            <input id="cost-per-item" name="cost-per-item" type="cost-per-item"
                                autoComplete="cost-per-item"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>

                    <div className="sm:col-span-6">
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            About Domain
                        </label>
                        <div className="mt-2">
                            <input id="cost-per-item" name="cost-per-item" type="cost-per-item"
                                autoComplete="cost-per-item"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>

                    <div className="sm:col-span-6">
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            Industry List
                        </label>
                        <div className="mt-2">
                            <input id="cost-per-item" name="cost-per-item" type="cost-per-item"
                                autoComplete="cost-per-item"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>

                    <div className="sm:col-span-6">
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            Sound URL
                        </label>
                        <div className="mt-2">
                            <input id="cost-per-item" name="cost-per-item" type="cost-per-item"
                                autoComplete="cost-per-item"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>

                    <div className="sm:col-span-6">
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            Sound
                        </label>
                        <div className="mt-2">
                            <input id="cost-per-item" name="cost-per-item" type="cost-per-item"
                                autoComplete="cost-per-item"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="sm:col-span-6">
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Brand Emotion
                </label>
                <div className="mt-2">
                    <input id="cost-per-item" name="cost-per-item" type="cost-per-item" autoComplete="cost-per-item"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
            </div>

            <div className="sm:col-span-6">
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Big Ideas/Emotions
                </label>
                <div className="mt-2">
                    <input id="cost-per-item" name="cost-per-item" type="cost-per-item" autoComplete="cost-per-item"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
            </div>

            <div className="sm:col-span-6">
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Syllables
                </label>
                <div className="mt-2">
                    <input id="cost-per-item" name="cost-per-item" type="cost-per-item" autoComplete="cost-per-item"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
            </div>

            <div className="sm:col-span-6">
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Style
                </label>
                <div className="mt-2">
                    <input id="cost-per-item" name="cost-per-item" type="cost-per-item" autoComplete="cost-per-item"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
            </div>

            <div className="sm:col-span-6">
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Length
                </label>
                <div className="mt-2">
                    <input id="cost-per-item" name="cost-per-item" type="cost-per-item" autoComplete="cost-per-item"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
            </div>

            <div className="sm:col-span-6">
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Enquiry
                </label>
                <div className="mt-2">
                    <input id="cost-per-item" name="cost-per-item" type="cost-per-item" autoComplete="cost-per-item"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
            </div>

            <div className="sm:col-span-6">
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Extension
                </label>
                <div className="mt-2">
                    <input id="cost-per-item" name="cost-per-item" type="cost-per-item" autoComplete="cost-per-item"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
            </div> */}

          {/* <div className="border-b border-gray-900/10 pb-12">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Notifications</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                    We'll always let you know about important changes, but you pick what else you want to hear about.
                </p>

                <div className="mt-10 space-y-10">
                    <fieldset>
                        <legend className="text-sm font-semibold leading-6 text-gray-900">By Email</legend>
                        <div className="mt-6 space-y-6">
                            <div className="relative flex gap-x-3">
                                <div className="flex h-6 items-center">
                                    <input id="comments" name="comments" type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                </div>
                                <div className="text-sm leading-6">
                                    <label htmlFor="comments" className="font-medium text-gray-900">
                                        Comments
                                    </label>
                                    <p className="text-gray-500">Get notified when someones posts a comment on a
                                        posting.</p>
                                </div>
                            </div>
                            <div className="relative flex gap-x-3">
                                <div className="flex h-6 items-center">
                                    <input id="candidates" name="candidates" type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                </div>
                                <div className="text-sm leading-6">
                                    <label htmlFor="candidates" className="font-medium text-gray-900">
                                        Candidates
                                    </label>
                                    <p className="text-gray-500">Get notified when a candidate applies for a job.</p>
                                </div>
                            </div>
                            <div className="relative flex gap-x-3">
                                <div className="flex h-6 items-center">
                                    <input id="offers" name="offers" type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                </div>
                                <div className="text-sm leading-6">
                                    <label htmlFor="offers" className="font-medium text-gray-900">
                                        Offers
                                    </label>
                                    <p className="text-gray-500">Get notified when a candidate accepts or rejects an
                                        offer.</p>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend className="text-sm font-semibold leading-6 text-gray-900">Push Notifications</legend>
                        <p className="mt-1 text-sm leading-6 text-gray-600">These are delivered via SMS to your mobile
                            phone.</p>
                        <div className="mt-6 space-y-6">
                            <div className="flex items-center gap-x-3">
                                <input id="push-everything" name="push-notifications" type="radio"
                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                <label htmlFor="push-everything"
                                    className="block text-sm font-medium leading-6 text-gray-900">
                                    Everything
                                </label>
                            </div>
                            <div className="flex items-center gap-x-3">
                                <input id="push-email" name="push-notifications" type="radio"
                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                <label htmlFor="push-email"
                                    className="block text-sm font-medium leading-6 text-gray-900">
                                    Same as email
                                </label>
                            </div>
                            <div className="flex items-center gap-x-3">
                                <input id="push-nothing" name="push-notifications" type="radio"
                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                <label htmlFor="push-nothing"
                                    className="block text-sm font-medium leading-6 text-gray-900">
                                    No push notifications
                                </label>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div> */}
        </div>


        {/* <VerificationConfirmationModal
          data={
            { verificationRecord: verificationRecord, domain: data.productDomain }
          }
          openVerificationConfirmationModal={openVerificationConfirmationModal}
          handleVerificationStatus={handleVerificationStatus}
        />

        <Loader loaderOpen={loaderOpen} setLoaderOpen={setLoaderOpen} />

        <VerificationFailedModal
          data={{
            openVerificationFailedModal: openVerificationFailedModal,
            setOpenVerificationFailedModal: setOpenVerificationFailedModal,
          }}
        />

        <VerificationSuccessModal
          data={{
            openVerificationSuccessModal: openVerificationSuccessModal,
            setOpenVerificationSuccessModal: setOpenVerificationSuccessModal,
          }}
        /> */}

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
            Cancel
          </button>
          <button type="button"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleCreateTxtRecord}>
            Save
          </button>
        </div>
      </form>
    </Card>
  )
}

export default EditDomain