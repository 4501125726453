import React from "react";
import {
  useFilters,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/solid";
// import SelectColumnFilter from "components/SelectColumnFilter";

export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  // Calculate the options for filtering
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a dropdown
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

const ApproveDomainsTable = ({
  domains,
  handleApprove,
  setSelectedRowIds,
  setShowApproveModal,
  setShowRejectModal,
}) => {
  // const [domainIds, setDomainIds] = useState([]);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  const vendors = [
    ...new Set(domains.map((domain) => domain.createdBy.merchant_id)),
  ]; // Retrieve unique vendor IDs

  const columns = React.useMemo(
    () => [
      {
        Header: "Select",
        accessor: "selection",
        Cell: ({ row }) => (
          <input
            type="checkbox"
            onChange={() => {
              row.toggleRowSelected();
            }}
            checked={row.isSelected}
          />
        ),
      },
      {
        Header: "Domain",
        accessor: "word",
      },
      {
        Header: "Date Created",
        accessor: "createdAt",
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: "Merchant ID",
        accessor: (row) => row.createdBy.merchant_id,
        id: "createdBy.merchant_id",
        Filter: SelectColumnFilter, // Added filter component
        filter: "includes", // Default filter
      },
      {
        Header: "Buy Now Price",
        accessor: "buyNowPrice",
      },
      {
        Header: "Floor Price",
        accessor: "floorPrice",
      },
      {
        Header: "Minimum Offer",
        accessor: "minimumOffer",
      },
      // {
      //   Header: "Action",
      //   Cell: ({ row }) => (
      //     <button
      //       className="beta-marker top-4 right-4 bg-red-600 text-white py-1 px-3 rounded-2xl font-semibold"
      //       onClick={() => handleApprove(row.original.id)}
      //     >
      //       Approve
      //     </button>
      //   ),
      // },
    ],
    [handleApprove]
  );

  const data = React.useMemo(() => domains, [domains]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: {
      pageIndex,
      // pageSize,
      filters,
      selectedRowIds,
    },
  } = tableInstance;

  // const handleApproveAll = () => {
  //   const selectedRows = Object.keys(selectedRowIds).map((id) => {
  //     const foundRow = tableInstance.rows.find((row) => row.id === id);
  //     return foundRow ? foundRow.original : null;
  //   });
  //   const rows = selectedRows.map((row) => row); // Extracting ids from selected rows
  //   setSelectedRowIds(rows); // Setting the domainIds state
  //   setShowApproveModal(true);
  //   // console.log("Selected Rows: ", selectedRows);

  // };

  // const handleRejectAll = () => {
  //   const selectedRows = Object.keys(selectedRowIds).map((id) => {
  //     const foundRow = tableInstance.rows.find((row) => row.id === id);
  //     return foundRow ? foundRow.original : null;
  //   });
  //   const rows = selectedRows.map((row) => row); // Extracting ids from selected rows
  //   setSelectedRowIds(rows); // Setting the domainIds state
  //   setShowRejectModal(true);
  //   // console.log("Selected Rows: ", selectedRows);
  // };

  const handleApproveAll = () => {
    if (Object.keys(selectedRowIds).length > 0) {
      const selectedRows = Object.keys(selectedRowIds).map((id) => {
        const foundRow = tableInstance.rows.find((row) => row.id === id);
        return foundRow ? foundRow.original : null;
      });
      const rows = selectedRows.map((row) => row); // Extracting ids from selected rows
      setSelectedRowIds(rows); // Setting the domainIds state
      setShowApproveModal(true);
      // console.log("Selected Rows: ", selectedRows);
    } else {
      // Show an error message or perform any other action for validation
      alert("Please select at least one row.");
    }
  };

  const handleRejectAll = () => {
    if (Object.keys(selectedRowIds).length > 0) {
      const selectedRows = Object.keys(selectedRowIds).map((id) => {
        const foundRow = tableInstance.rows.find((row) => row.id === id);
        return foundRow ? foundRow.original : null;
      });
      const rows = selectedRows.map((row) => row); // Extracting ids from selected rows
      setSelectedRowIds(rows); // Setting the domainIds state
      setShowRejectModal(true);
      // console.log("Selected Rows: ", selectedRows);
    } else {
      // Show an error message or perform any other action for validation
      alert("Please select at least one row.");
    }
  };

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Approve Domains
        </div>

        <CardMenu />

        <div className="mt-4 flex items-center">
          <strong>Filter By Vendor: &nbsp;</strong>
          <select
            value={filters[0]?.value || ""}
            onChange={(e) => {
              tableInstance.setFilter("createdBy.merchant_id", e.target.value); // Apply the filter to the "createdBy.merchant_id" column using tableInstance
            }}
            className="rounded-md border px-3 py-2 transition duration-300 focus:border-blue-500 focus:outline-none"
          >
            <option value="">All</option>
            {vendors.map((vendor, index) => (
              <option key={index} value={vendor}>
                {vendor}
              </option>
            ))}
          </select>
        </div>
      </header>

      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
        <table
          className="w-full"
          variant="simple"
          color="gray-500"
          mb="24px"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, columnIndex) => (
                  <th
                    className="border-b border-gray-200 pb-[10px] pr-8 text-start dark:!border-navy-700"
                    {...column.getHeaderProps(column.getSortByToggleProps())} // Updated line
                    key={columnIndex}
                  >
                    <div>
                      {column.render("Header")}
                      <span className="text-xs">
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " ▼"
                            : " ▲"
                          : " ▲▼"}
                      </span>
                    </div>
                    {/* {column.render('Filter')} */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="divide-y divide-gray-200" {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, cellIndex) => (
                    <td
                      className="pb-[16px] pt-[14px] sm:text-[14px]"
                      {...cell.getCellProps()}
                      key={cellIndex}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        {page.length === 0 && (
          <tr className="flex h-32  w-full items-center justify-center font-semibold text-red-600">
            No data found
          </tr>
        )}
      </div>
      <div className="mt-4 flex justify-end">
        <button
          onClick={handleApproveAll}
          className="mr-2 cursor-pointer rounded-lg bg-green-500 px-4 py-2 text-sm font-medium text-white"
        >
          Approve Selected
        </button>
        <button
          onClick={handleRejectAll}
          className="cursor-pointer rounded-lg bg-red-500 px-4 py-2 text-sm font-medium text-white"
        >
          Reject Selected
        </button>
      </div>
      <div className="mt-8 flex items-center justify-between pb-4">
        <div className="flex items-center justify-between gap-1 md:mt-8 md:pb-4">
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="flex cursor-pointer items-center rounded-lg bg-[#01afef] px-4 py-2 text-sm font-medium text-white"
          >
            <ChevronDoubleLeftIcon className="h-4 w-4 text-white" /> Previous
          </button>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="flex cursor-pointer items-center rounded-lg bg-[#01afef] px-4 py-2 text-sm font-medium text-white"
          >
            Next <ChevronDoubleRightIcon className="h-4 w-4 text-white" />
          </button>
        </div>
        <div>
          Page <strong>{pageIndex + 1}</strong> of{" "}
          <strong>{pageOptions.length}</strong>
        </div>
      </div>
    </Card>
  );
};

export default ApproveDomainsTable;
