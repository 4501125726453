import InputField from "components/fields/InputField";
// import { FcGoogle } from "react-icons/fc";
// import Checkbox from "components/checkbox";
import { useState } from "react";
import { LOGIN_MUTATION } from "graphql/auth/mutations";
import { useMutation } from "@apollo/client";
import { useAuth } from "App";
import { Link } from "react-router-dom";
import ErrorAlert from "components/ErrorAlert";

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorAlert, setErrorAlert] = useState("");

  const handleEmailChange = (newValue) => {
    setEmail(newValue);
  };

  const handlePasswordChange = (newValue) => {
    setPassword(newValue);
  };

  const [login] = useMutation(LOGIN_MUTATION);
  const { onLogin } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    // console.log(email, password);
    try {
      const response = await login({
        variables: { loginInput: { email: email, password: password } },
      });

      const token = await response.data.login;
      // await localStorage.setItem('token', token);
      /**
       * TODO: Implement this.
       */
      await setTimeout(() => {
        if (token) {
          onLogin(token);
        }
      }, 500);
    } catch (error) {
      // console.log("hello");
      // console.error('Login failed:', error);
      setErrorAlert(error.message);
    }
  };

  const onClose = () => {
      setErrorAlert('');
  }

  return (
    <div className="mb-16  flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="flex flex-col-reverse lg:flex-row items-center justify-between w-full">
        <div className="m-8 w-full max-w-full flex-col items-center lg:max-w-[420px]">
          {errorAlert && (
            <span>
              <ErrorAlert message={errorAlert} onClose={onClose} />
            </span>
          )}
          <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
            Sign In
          </h4>
          <p className="mb-9 ml-1 text-base text-gray-600">
            Enter your Email and password to sign in!
          </p>
          {/* <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800">
          <div className="rounded-full text-xl">
            <FcGoogle />
          </div>
          <h5 className="text-sm font-medium text-navy-700 dark:text-white">
            Sign In with Google
          </h5>
        </div>
        <div className="mb-6 flex items-center  gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
          <p className="text-base text-gray-600 dark:text-white"> or </p>
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div> */}
          {/* Email */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Email*"
            placeholder="Email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            type="text"
          />

          {/* Password */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Password*"
            placeholder="Min. 8 characters"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            type="password"
          />
          {/* Checkbox */}
          <div className="mb-4 flex items-center justify-between px-2">
            {/* <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged In
            </p>
          </div> */}
          {/* <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href=" "
          >
            Forgot Password?
          </a> */}
          <Link
            to={'/auth/forgot-password'}
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Forgot Password
          </Link>
          </div>
          <button
            type="button"
            onClick={handleLogin}
            className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Sign In
          </button>
          <div className="mt-4">
            <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
              Not registered yet?
            </span>
            <Link
              to={"/auth/register"}
              className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            >
              Create an account
            </Link>
          </div>
        </div>
        <div>
          <img src="/Images/login_page.png" />
        </div>
      </div>
    </div>
  );
}
