// import InputField from "components/fields/InputField";
// import { FcGoogle } from "react-icons/fc";
// import Checkbox from "components/checkbox";
import { useState } from "react";
import useForgotPasswordFormContext from "hooks/useForgotPasswordFormContext";
import ForgotPasswordFormInputs from "./ForgotPassword/ForgotPasswordFormInputs";
import { useMutation } from "@apollo/client"
import { CREATE_USER_MUTATION } from "graphql/auth/mutations"
import ErrorAlert from "components/ErrorAlert";
import { Link, useNavigate } from "react-router-dom";
import { GENERATE_OTP_MUTATION } from "graphql/users/mutations";
import { VERIFY_OTP_MUTATION } from "graphql/users/mutations";
import { UPDATE_PASSWORD_MUTATION } from "graphql/users/mutations";
import Loader from "components/Loader";

export default function ForgotPassword() {

    const navigate = useNavigate()

    const [createUser] = useMutation(CREATE_USER_MUTATION);
    const [generateOTPMuation] = useMutation(GENERATE_OTP_MUTATION);
    const [verifyOTPMutation] = useMutation(VERIFY_OTP_MUTATION);
    const [updatePasswordMutation] = useMutation(UPDATE_PASSWORD_MUTATION);

    const {
        // page,
        setPage,
        data,
        // title,
        canSubmit,
        canSubmitSetPassword,
        // disablePrev,
        // disableNext,
        // prevHide,
        nextHide,
        submitPasswordHide,
        submitHide
    } = useForgotPasswordFormContext()

    const [error, setError] = useState('');
    const [loaderOpen, setLoaderOpen] = useState(false);

    const handlePrev = () => setPage(prev => prev - 1)

    const handleNext = () => {
        setPage(prev => prev + 1)
    }

    const generateOTP = e => {
        e.preventDefault();
        setLoaderOpen(true);
        generateOTPMuation({
            variables: {
                email: data.email
            }
        })
            .then((response) => {
                if (response.data.generateOTP) {
                    setPage(prev => prev + 1)
                    setLoaderOpen(false);
                } else {
                    setLoaderOpen(false);
                    throw ("Something went Wrong")
                }
            })
            .catch((error) => {
                // Handle errors here
                console.error("Error Encountered: ", error);
                if (error.graphQLErrors && error.graphQLErrors.length > 0) {
                    const errorMessage = error.graphQLErrors[0]?.message;
                    if (errorMessage) {
                        setLoaderOpen(false);
                        setError(errorMessage);
                    } else {
                        setLoaderOpen(false);
                        // Handle other GraphQL errors
                    }
                } else {
                    // Handle non-GraphQL errors (e.g., network errors)
                    setError(error.message);
                    setLoaderOpen(false);
                }
            })
    }

    const submitOTP = e => {
        e.preventDefault();
        setLoaderOpen(true);
        verifyOTPMutation({
            variables: {
                email: data.email,
                otp: data.otp
            }
        })
            .then((response) => {
                if (response.data.verifyOTP) {
                    setPage(prev => prev + 1);
                    setLoaderOpen(false)
                } else {
                    setError("OTP Incorrect or Expired. Please regenerate.")
                    setLoaderOpen(false)
                    throw ({ "message": "OTP Incorrect or Expired. Please regenerate." })
                }
            })
            .catch((error) => {
                // Handle errors here
                console.error("Error Encountered: ", error);
                if (error.graphQLErrors && error.graphQLErrors.length > 0) {
                    const errorMessage = error.graphQLErrors[0]?.message;
                    if (errorMessage) {
                        setError(errorMessage);
                        setLoaderOpen(false);
                    } else {
                        // Handle other GraphQL errors
                        setLoaderOpen(false);
                    }
                } else {
                    // Handle non-GraphQL errors (e.g., network errors)
                    setError(error.message);
                    setLoaderOpen(false);
                }
            })
    }

    const submitSetPassword = (e) => {
        e.preventDefault();
        setLoaderOpen(true);

        if (!(data.password === data.confirmPassword)) {
            setError('Password and Confirm Password fields should have the same value.')
        }

        updatePasswordMutation({
            variables: {
                email: data.email,
                newPassword: data.password
            }
        })
            .then(response => {
                if (response.data.updatePassword) {
                    navigate('/auth')
                    setLoaderOpen(false);
                }
            })
            .catch(error => {
                console.error("Error Encountered: ", error);
                if (error.graphQLErrors && error.graphQLErrors.length > 0) {
                    const errorMessage = error.graphQLErrors[0]?.message;
                    if (errorMessage) {
                        setLoaderOpen(false);
                        setError(errorMessage);
                    } else {
                        setLoaderOpen(false);
                        // Handle other GraphQL errors
                    }
                } else {
                    // Handle non-GraphQL errors (e.g., network errors)
                    setError(error.message);
                    setLoaderOpen(false);
                }
            })
    }

    const onClose = () => {
        setError('');
    }

    return (
        <form className="form flex-col">

            {/* Conditionally render the error component */}
            {error && <ErrorAlert message={error} onClose={onClose} />}

            <Loader loaderOpen={loaderOpen} setLoaderOpen={setLoaderOpen} />

            {/* <header className="form-header">
                <h2>{title[page]}</h2>

                <div className="button-container">

                <button type="button" className={`button ${prevHide}`} onClick={handlePrev} disabled={disablePrev}>Prev</button>

                <button type="button" className={`button ${nextHide}`} onClick={handleNext} disabled={disableNext}>Next</button>

                <button type="submit" className={`button ${submitHide}`} disabled={!canSubmit}>Submit</button>
                </div>
            </header> */}
            <div className="flex flex-col-reverse lg:flex-row items-center justify-between w-full">
                <div className="w-full max-w-full flex-col items-center lg:max-w-[420px] m-8">
                    <ForgotPasswordFormInputs />
                    <div className="mt-4">
                        <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
                            Already Have an Acccount?
                        </span>
                        <Link
                            to={"/auth/sign-in"}
                            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                        >
                            Sign In
                        </Link>
                    </div>
                    <div className="button-container gap-4 w-full max-w-full flex items-center lg:pl-0 xl:max-w-[420px]">

                        {/* <button
                            className={`
                                linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 
                                ${prevHide}
                            `}
                            type="button"
                            onClick={handlePrev}
                            disabled={disablePrev}
                            >
                            Prev
                            </button> */}

                        <button
                            className={`
                                linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200
                                ${nextHide}
                            `}
                            onClick={generateOTP}
                        // disabled={disableNext}
                        >
                            Generate OTP
                        </button>

                        <button
                            className={`
                                linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200
                                ${submitHide}
                            `}
                            onClick={submitOTP}
                        // disabled={!canSubmit}
                        >
                            Submit OTP
                        </button>

                        <button
                            className={`
                        linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200
                        ${submitPasswordHide}
                    `}
                            onClick={submitSetPassword}
                        // disabled={!canSubmitSetPassword}
                        >
                            Create New Password
                        </button>
                        {/* <button type="button" className={`button ${prevHide}`} onClick={handlePrev} disabled={disablePrev}>Prev</button>

        <button type="button" className={`button ${nextHide}`} onClick={handleNext} disabled={disableNext}>Next</button>

        <button type="submit" className={`button ${submitHide}`} disabled={!canSubmit}>Submit</button> */}
                    </div>
                </div>
                <div>
                    <img src="/Images/login_page.png" />
                </div>
            </div>
        </form>
    );
}
