import { gql } from "@apollo/client";

export const GET_NOTIFICATIONS_QUERY = gql`
  query GetNotifications($userId:Int!){
    getNotifications(userId:$userId) {
        id
        userId
        title
        message
        createdAt
    }
    }
`;